/**
 * Icons
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Hilfsklasse fuer '@extend' (Iconschriften: 'FontAwesome').
 *
 * ========================================================================== */

.fa-icon-helper {
	display        : inline-block;
	font-family    : 'FontAwesome';
	font-size      : inherit;
	font-style     : normal;
	font-weight    : normal;
	line-height    : 1;
	text-rendering : optimizeSpeed;
}

.fi-icon-helper {
	display        : inline-block;
	font-family    : 'FontImas';
	font-size      : inherit;
	font-style     : normal;
	font-weight    : normal;
	line-height    : 1;
	text-rendering : optimizeSpeed;
}