/**
 * Seiteninhalte
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package Workspace
 */

// Abstand Trigger-Button eines 'Toggle containers' (JS-generiert)
.tc-trigger {
	display    : block;
	padding    : $padding-base-vertical-xsmall 0;
	text-align : center;
}

/* =============================================================================
 *
 * Overlay-Contentanpassungen
 *
 * ========================================================================== */

.popup-content-wrapper { @extend %child-reset-margin-last-top; }