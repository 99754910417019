/**
 * Typografie
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Ueberschriften
 *
 * ========================================================================== */

$-heading-line-height : 2px;
$-heading-line-width  : 90px;
$-heading-line-offset : ceil($-heading-line-width / 2);

/* Ueberschriften global */
@for $i from 1 through 6 {
	h#{$i},
	.h#{$i} {
		clear       : both;
		display     : block;
		font-family : $font-family-headings;
		position    : relative;
	}
}

/* Ueberschrift erster Ordnung */
h1,
.h1 {
	color          : $h1-color;
	font-family    : $font-family-secondary;
	font-size      : cast-unit($font-unit-base, $h1-font-size);
	font-style     : $h1-font-style;
	font-variant   : $h1-font-variant;
	font-weight    : $h1-font-weight;
	line-height    : calc-line-height($body-font-size, $h1-line-height);
	margin         : $h1-margin;
	padding-bottom : $padding-base-vertical;
	text-align     : center;
	text-transform : $h1-text-transform;

	&::before {
		background-color : $h1-color;
		bottom           : 0;
		content          : "";
		height           : $-heading-line-height;
		left             : 50%;
		margin-left      : -#{$-heading-line-offset};
		overflow         : hidden;
		position         : absolute;
		width            : $-heading-line-width;
	}

	small,
	.small { color : $h1-small-color; }
}

/* Ueberschrift zweiter Ordnung */
h2,
.h2 {
	color          : $h2-color;
	font-size      : cast-unit($font-unit-base, $h2-font-size);
	font-style     : $h2-font-style;
	font-variant   : $h2-font-variant;
	font-weight    : $h2-font-weight;
	line-height    : calc-line-height($body-font-size, $h2-line-height);
	margin         : $h2-margin;
	text-transform : $h2-text-transform;

	small,
	.small { color : $h2-small-color; }
}

/* Ueberschrift dritter Ordnung */
h3,
.h3 {
	color          : $h3-color;
	font-size      : cast-unit($font-unit-base, $h3-font-size);
	font-style     : $h3-font-style;
	font-variant   : $h3-font-variant;
	font-weight    : $h3-font-weight;
	line-height    : calc-line-height($body-font-size, $h3-line-height);
	margin         : $h3-margin;
	text-transform : $h3-text-transform;

	small,
	.small { color : $h3-small-color; }
}

/* Ueberschriften vierter bis sechster Ordnung */
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	color          : $h4-color;
	font-size      : cast-unit($font-unit-base, $h4-font-size);
	font-style     : $h4-font-style;
	font-variant   : $h4-font-variant;
	font-weight    : $h4-font-weight;
	line-height    : calc-line-height($body-font-size, $h4-line-height);
	margin         : $h4-margin;
	text-transform : $h4-text-transform;

	small,
	.small { color : $h4-small-color; }
}

/* =============================================================================
 *
 * Kontextabhaengige Farben
 *
 * ========================================================================== */

@include text-color-variant('.text-light', lighten($body-text-color, 30%));
@include text-color-variant('.text-lighter', lighten($body-text-color, 45%));
@include text-color-variant('.text-dark', darken($body-text-color, 20%));
@include text-color-variant('.text-darker', darken($body-text-color, 40%));


.text-muted { color : $text-muted-color; }

@include text-color-variant('.text-invalid', $state-danger-color);
@include text-color-variant('.text-danger', $state-danger-color);
@include text-color-variant('.text-info', $state-info-color);
@include text-color-variant('.text-primary', $brand-primary);
@include text-color-variant('.text-secondary', $brand-secondary);
@include text-color-variant('.text-success', $state-success-color);
@include text-color-variant('.text-warning', $state-warning-color);

.bg-primary { color : #fff; }

@include bg-variant('.bg-error', $state-danger-bg-color);
@include bg-variant('.bg-danger', $state-danger-bg-color);
@include bg-variant('.bg-info', $state-info-bg-color);
@include bg-variant('.bg-primary', $brand-primary);
@include bg-variant('.bg-success', $state-success-bg-color);
@include bg-variant('.bg-warning', $state-warning-bg-color);

/* =============================================================================
 *
 * Ausrichtungen
 *
 * ========================================================================== */

.text-center { text-align : center; }

.text-left { text-align : left; }

.text-right { text-align : right; }

/* =============================================================================
 *
 * Text
 *
 * ========================================================================== */

p,
blockquote,
pre,
hr {
	@extend %clear-both;

	margin : $margin-base-vertical 0;
}

cite {
	color      : $color-gray;
	font-style : $font-style-italic;
}

/* -----------------------------------------------------------------------------
 * Textgroessen
 * Achtung: Verschachtelte Zuweisungen sollten  zurueckgesetzt werden.
 * -------------------------------------------------------------------------- */

.text-big-highlight {
	$-font-size   : 48px;
	$-line-height : 60px;

	clear          : both;
	color          : $brand-secondary;
	display        : block;
	font-family    : $font-family-headings;
	font-size      : cast-unit($font-unit-base, $-font-size);
	font-weight    : $font-weight-medium;
	line-height    : ($-line-height / $-font-size);
	position       : relative;
	text-transform : uppercase;

	&::before {
		background-color : $brand-primary;
		content          : "";
		height           : ($-heading-line-height * 2);
		left             : 0;
		overflow         : hidden;
		position         : absolute;
		top              : -#{$margin-base-vertical-large};
		width            : $-heading-line-width;
	}
}

.large,
.text-large { font-size : cast-unit($font-unit-base, $font-size-large); }

.xlarge,
.text-xlarge { font-size : cast-unit($font-unit-base, $font-size-xlarge); }

small,
.small,
.text-small { font-size : cast-unit($font-unit-base, $font-size-small); }

.xsmall,
.text-xsmall { font-size : cast-unit($font-unit-base, $font-size-xsmall); }

.large,
small,
.small,
.xsmall,
.text-small,
.text-xsmall {
	.large,
	.xsmall,
	small,
	.small,
	.text-small,
	.text-xsmall { font-size : 1em; }
}

.text-uppercase { text-transform : uppercase; }

/* -----------------------------------------------------------------------------
 * Blockquote
 * -------------------------------------------------------------------------- */

blockquote {
	background   : $blockquote-bg-color;
	border-left  : $blockquote-border-width $blockquote-border-style $blockquote-border-color;
	clear        : both;
	color        : $blockquote-color;
	font-style   : $blockquote-font-style;
	font-weight  : $blockquote-font-weight;
	line-height  : $line-height-small;
	padding      : $blockquote-padding;
	position     : relative;

	// (1)
	@if (variable-exists(blockquote-font-family) and $blockquote-font-family != '') {
		font-family : $blockquote-font-family;
	} @else {
		font-family : inherit;
	}

	// (1)(2)
	@if (
		variable-exists(blockquote-font-size) and
		type-of($blockquote-font-size) == 'number' and
		unitless($blockquote-font-size) == false and
		unit($blockquote-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $blockquote-font-size);
	} @else {
		font-size : inherit;
	}

	// (1)
	@if (variable-exists(blockquote-margin) and $blockquote-margin != '') {
		margin: $blockquote-margin;
	}

	> p {
		margin  : 0;
		padding : 0;
	}

	&:before {
		@extend .fa-icon-helper;

		color     : $brand-tertiary;
		content   : '\f10d';
		font-size : 1.25em;
		left      : 25px;
		position  : absolute;
		top       : 10px;
	}

	cite {
		color       : $brand-tertiary;
		display     : block;
		font-size   : .5625em;
		font-style  : $font-style-normal;
		font-weight : $font-weight-normal;
		padding-top : $padding-base-vertical;

		&:before,
		&:after { content : '-' }

		&:before { padding-right : $padding-base-horizontal-xsmall; }

		&:after { padding-left : $padding-base-horizontal-xsmall; }
	}

	&.pull-right {
		border-right : 5px solid $blockquote-border-color;
		border-left  : 0;
	}
}

/* -----------------------------------------------------------------------------
 * Sonstiges
 * -------------------------------------------------------------------------- */

hr { border-top-color : $color-gray-light; }

// TODO: '.description' not used
//.description {
//	background-color : $color-gray-lighter;
//	color            : $color-black;
//	font-style       : $font-style-normal;
//	font-weight      : $font-weight-normal;
//	padding          : $padding-base-horizontal;
//}

address { line-height : inherit; }

/*
 * Kursiver Text
 */

.italic,
em, .em { font-style : $font-style-italic; }

/*
 * Fetter Text
 */
strong,
.strong { font-weight : $font-weight-bold; }

// FIXED: Font-Family-Render-Fix
strong {
	// Bei der aktuellen Schriftfamilie laeuft das <strong/> in den umgebenden
	// Text rein :(!
	padding-left  : 2px;
	padding-right : 2px;
}

mark,
.mark {
	background-color : $mark-bg-color;
	padding          : $mark-padding;
}

/* Abkuerzungen etc. */
abbr[title],
abbr[data-original-title],
dfn[title],
acronym[title] {
	border-bottom : $abbr-border-bottom;
	cursor        : $cursor-help;
}

/* Datum, Zeit */
.time {
	display : none;

	.oclock {
		display : none;

		span { padding-right : 4px; }
	}
}

/* -----------------------------------------------------------------------------
 * Code
 * -------------------------------------------------------------------------- */

code,
kbd,
pre,
samp { font-family : $font-family-monospace; }

// Inline code
code {
	background-color : $code-bg-color;
	border-radius    : $border-radius-base;
	color            : $code-color;
	font-size        : 80%;
}

// User input typically entered via keyboard
kbd {
	background-color : $kbd-bg-color;
	border-radius    : $border-radius-small;
	box-shadow       : $kbd-box-shadow;
	color            : $kbd-color;

	kbd {
		background-color : transparent;
		box-shadow       : none;
		font-weight      : $font-weight-normal;
	}
}

// Blocks of code
pre {
	$-f-size : $font-size-base - 1;
	background-color : $pre-bg-color;
	border           : $pre-border;
	border-radius    : $pre-border-radius;
	color            : $pre-color;
	font-size        : cast-unit($font-unit-base, $-f-size);
	line-height      : $line-height-base;

	// Account for some code outputs that place code tags in pre tags
	code {
		padding          : 0;
		font-size        : inherit;
		color            : inherit;
		white-space      : pre-wrap;
		background-color : transparent;
		border-radius    : 0;
	}
}