/**
 * Seitenfuss
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.main-footer {
	&-wrap {
		border-top     : 1px solid $color-gray-light;
		padding-top    : $padding-base-vertical-large;
		padding-bottom : $padding-base-vertical-large;
	}

	.copy,
	.nav-service {
		float : left;
		width : 50%;
	}

	.copy {
		color     : $color-gray;
		font-size : cast-unit($font-unit-base, $font-size-xlarge);
	}

	.nav-service { text-align : right; }
}