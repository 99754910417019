/**
 * Swiper 3.2.7
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2015, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: December 7, 2015
 */

.swiper-container {
	margin   : 0 auto;
	overflow : hidden;
	position : relative;
	z-index  : 1;
}

.swiper-container-no-flexbox .swiper-slide { float : left; }

.swiper-container-vertical > .swiper-wrapper {
	-moz-box-orient    : vertical;
	-webkit-box-orient : vertical;
	flex-direction     : column;
}

.swiper-wrapper {
	box-sizing          : content-box;
	display             : flex;
	height              : 100%;
	position            : relative;
	transition-property : transform;
	width               : 100%;
	z-index             : 1;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper { transform : translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper { flex-wrap : wrap; }

.swiper-container-free-mode > .swiper-wrapper {
	margin                     : 0 auto;
	transition-timing-function : ease-out;
}

.swiper-slide {
	flex-shrink : 0;
	height      : 100%;
	position    : relative;
	width       : 100%;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide { height : auto; }

.swiper-container-autoheight .swiper-wrapper {
	align-items         : flex-start;
	transition-property : transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
	left           : 0;
	opacity        : 0;
	pointer-events : none;
	position       : absolute;
	top            : 0;
	z-index        : -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal { touch-action : pan-y; }

.swiper-wp8-vertical { touch-action : pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
	background-position : center;
	background-repeat   : no-repeat;
	background-size     : 27px 44px;
	cursor              : pointer;
	height              : 44px;
	margin-top          : -22px;
	position            : absolute;
	top                 : 50%;
	width               : 27px;
	z-index             : 10;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	cursor         : auto;
	opacity        : 0.35;
	pointer-events : none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
	left             : 10px;
	right            : auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black { background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white { background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
	left             : auto;
	right            : 10px;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black { background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white { background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
	position   : absolute;
	text-align : center;
	transform  : translate3d(0, 0, 0);
	transition : 300ms;
	z-index    : 10;
}

.swiper-pagination.swiper-pagination-hidden { opacity : 0; }

.swiper-pagination-bullet {
	background    : #000;
	border-radius : 100%;
	display       : inline-block;
	height        : 8px;
	opacity       : 0.2;
	width         : 8px;
}

button.swiper-pagination-bullet {
	appearance : none;
	border     : none;
	box-shadow : none;
	margin     : 0;
	padding    : 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet { cursor : pointer; }

.swiper-pagination-white .swiper-pagination-bullet { background : #fff; }

.swiper-pagination-bullet-active {
	background : #007aff;
	opacity    : 1;
}

.swiper-pagination-white .swiper-pagination-bullet-active { background : #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active { background : #000; }

.swiper-container-vertical > .swiper-pagination {
	right     : 10px;
	top       : 50%;
	transform : translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
	display : block;
	margin  : 5px 0;
}

.swiper-container-horizontal > .swiper-pagination {
	bottom : 10px;
	left   : 0;
	width  : 100%;
}

.swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet { margin : 0 5px; }

/* 3D Container */
.swiper-container-3d { perspective : 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow { transform-style : preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
	height         : 100%;
	left           : 0;
	pointer-events : none;
	position       : absolute;
	top            : 0;
	width          : 100%;
	z-index        : 10;
}

.swiper-container-3d .swiper-slide-shadow-left { background-image : linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-right { background-image : linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-top { background-image : linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-bottom { background-image : linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper { -ms-perspective : 1200px; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide { transition-timing-function : ease-out; }

.swiper-container-fade .swiper-slide {
	pointer-events : none;
}

.swiper-container-fade .swiper-slide .swiper-slide { pointer-events : none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active { pointer-events : auto; }

/* Cube */
.swiper-container-cube { overflow : visible; }

.swiper-container-cube .swiper-slide {
	backface-visibility : hidden;
	height              : 100%;
	pointer-events      : none;
	transform-origin    : 0 0;
	visibility          : hidden;
	width               : 100%;
	z-index             : 1;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide { transform-origin : 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
	pointer-events : auto;
	visibility     : visible;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
	backface-visibility : hidden;
	z-index             : 0;
}

.swiper-container-cube .swiper-cube-shadow {
	background : #000;
	bottom     : 0;
	filter     : blur(50px);
	height     : 100%;
	left       : 0;
	opacity    : 0.6;
	position   : absolute;
	width      : 100%;
	z-index    : 0;
}

/* Scrollbar */
.swiper-scrollbar {
	border-radius    : 10px;
	position         : relative;
	-ms-touch-action : none;
	background       : rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
	position : absolute;
	left     : 1%;
	bottom   : 3px;
	z-index  : 50;
	height   : 5px;
	width    : 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
	position : absolute;
	right    : 3px;
	top      : 1%;
	z-index  : 50;
	width    : 5px;
	height   : 98%;
}

.swiper-scrollbar-drag {
	height        : 100%;
	width         : 100%;
	position      : relative;
	background    : rgba(0, 0, 0, 0.5);
	border-radius : 10px;
	left          : 0;
	top           : 0;
}

.swiper-scrollbar-cursor-drag {
	cursor : move;
}

/* Preloader */
.swiper-lazy-preloader {
	animation        : swiper-preloader-spin 1s steps(12, end) infinite;
	height           : 42px;
	left             : 50%;
	margin-left      : -21px;
	margin-top       : -21px;
	position         : absolute;
	top              : 50%;
	transform-origin : 50%;
	width            : 42px;
	z-index          : 10;
}

.swiper-lazy-preloader:after {
	background-image    : url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position : 50%;
	background-repeat   : no-repeat;
	background-size     : 100%;
	content             : "";
	display             : block;
	height              : 100%;
	width               : 100%;
}

.swiper-lazy-preloader-white:after { background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
	100% { -webkit-transform : rotate(360deg); }
}

@keyframes swiper-preloader-spin {
	100% { transform : rotate(360deg); }
}