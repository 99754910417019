/**
 * Stadtteilfinder (Konfiguration)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$df-spacing-large   : ($margin-base-vertical-large * 2) !default;
$df-spacing-medium  : ($margin-base-vertical-large + $margin-base-vertical-small) !default;

/* =============================================================================
 *
 * Ansicht: Fragen
 *
 * ========================================================================== */

/* -----------------------------------------------------------------------------
 * Fortschrittsanzeige der Fragen.
 * -------------------------------------------------------------------------- */

$df-q-progress-bg-color     : transparent !default;
$df-q-progress-border-color : $color-gray-light !default;
$df-q-progress-border-style : solid !default;
$df-q-progress-border-width : 1px !default;

/* Standardeintrag */
$df-q-progress-item-bg-color : transparent !default;
$df-q-progress-item-color    : $color-white !default;
$df-q-progress-item-height   : 2px !default;

/* aktiver Eintrag */
$df-q-progress-item-current-bg-color  : $color-gray-light !default;
$df-q-progress-item-current-color     : $df-q-progress-item-color !default;

/* beantworteter Eintrag */
$df-q-progress-item-answered-bg-color : $brand-primary !default;
$df-q-progress-item-answered-color    : $df-q-progress-item-color !default;

/* =============================================================================
 *
 * Ansicht: Ergebnisse
 *
 * ========================================================================== */

/* Prozentanzeige */
$df-r-percent-bg-color     : $brand-primary !default;
$df-r-percent-border-color : $brand-primary !default;
$df-r-percent-border-style : solid !default;
$df-r-percent-border-width : 2px !default;
$df-r-percent-color        : $color-white !default;
