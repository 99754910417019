/**
 * Tabellen (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* -----------------------------------------------------------------------------
 * Tabellenstandards
 * -------------------------------------------------------------------------- */

caption {
	padding    : $table-default-caption-padding;
	color      : $table-default-caption-color;
	text-align : $table-default-caption-text-align;
}

table {
	background-color : transparent;
	margin           : $table-default-margin;
	max-width        : $table-default-width;
	width            : $table-default-width;

	th,
	td {
		border         : 0;
		color          : $table-default-color;
		line-height    : $line-height-base;
		padding        : $table-default-padding;
		text-align     : $table-default-text-align;
		vertical-align : top;
	}

	// Tabellenkopf
	> thead > tr {
		> th,
		> td {
			color          : $table-default-header-color;
			font-weight    : $table-default-header-font-weight;
			text-align     : $table-default-header-text-align;
			text-transform : $table-default-header-text-transform;

			@if (variable-exists(table-default-header-font-family) and $table-default-header-font-family != '') {
				font-family : $table-default-header-font-family;
			}

			@if (
				variable-exists(table-default-header-font-size) and
				type-of($table-default-header-font-size) == 'number' and
				unitless($table-default-header-font-size) == false and
				unit($table-default-header-font-size) == 'px'
			) {
				font-size : cast-unit($font-unit-base, $table-default-header-font-size);
			} @else {
				font-size : inherit;
			}
		}
	}

	// Zeilenkopf
	> tbody > tr {
		> th {
			color       : $table-default-row-header-color;
			font-weight : $table-default-row-header-font-weight;
			text-align  : $table-default-row-header-text-align;
		}
	}

	// Hintergrundfarbe verschachtelter Tabellen zuruecksetzen.
	table { background-color : $table-nested-bg-color; }
}

/* -----------------------------------------------------------------------------
 * @Tabelle '.table'
 * -------------------------------------------------------------------------- */

.table {
	> thead > tr {
		> th,
		> td {
			border-bottom : $table-header-border;
			border-top    : 0 none;
			padding       : $table-default-padding;
		}
	}

	> tbody > tr,
	> tfoot > tr {
		> th,
		> td {
			border-bottom : $table-row-border;
			border-top    : 0 none;
			padding       : $table-default-padding;
		}
	}

	// <thead/> hat standardmaessig keinen oberen Rahmen
	> caption + thead,
	> colgroup + thead,
	> thead:first-child {
		> tr:first-child {
			> th,
			> td { border-top : 0; }
		}
	}

	// Mehrere <body/>-Elemente
	> tbody + tbody { border-top : $table-row-border; }
}

/* -----------------------------------------------------------------------------
 * @Tabelle '.table-condensed'
 * -------------------------------------------------------------------------- */

.table-condensed {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td { padding : $table-condensed-cell-padding-vertical $table-condensed-cell-padding-horizontal; }
		}
	}
}

/* -----------------------------------------------------------------------------
 * @Bordered
 * -------------------------------------------------------------------------- */

.table-bordered {
	border : $table-bordered-border;

/*	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td { border : $table-bordered-border; }
		}
	}

	> thead > tr {
		> th,
		> td { border-bottom-width : 1px; }
	}*/
}

/* -----------------------------------------------------------------------------
 * @Zebra
 * -------------------------------------------------------------------------- */

.table-striped > tbody > tr {
	&:nth-child(even) { background-color : $table-zebra-bg-color-even; }

	&:nth-child(odd) { background-color : $table-zebra-bg-color-odd; }
}

/* -----------------------------------------------------------------------------
 * @Hover
 * -------------------------------------------------------------------------- */

.table-hover > tbody > tr:hover { background-color : $table-hover-bg-color-hover; }

/* -----------------------------------------------------------------------------
 * @Hintergrundfarben
 * -------------------------------------------------------------------------- */

/*
 * Kontextbezogene Varianten
 */

@include table-row-variant('active', $table-bg-color-active);

@include table-row-variant('success', $table-bg-color-success);

@include table-row-variant('info', $table-bg-color-info);

@include table-row-variant('warning', $table-bg-color-warning);

@include table-row-variant('danger', $table-bg-color-danger);

/*table,
table caption,
thead,
tbody,
tfoot,
tr,
th,
td {
	background-color : map-get($tables, 'default-background-color');
	margin           : 0;
	padding          : 0;
}

table {
	background-color : map-get($tables, 'default-background-color');
	border-collapse  : collapse;
	border-spacing   : 0;
	clear            : both;
	empty-cells      : hide;
	margin           : map-get($measurements, 'margin-vertical') 0;
	min-width        : map-get($tables, 'default-width');
	padding          : 0;
}

table caption,
table tfoot { display : none; }

th,
td {
	border         : 0 none transparent;
	font-weight    : normal;
	text-align     : left;
	vertical-align : top;

	&.text-top { vertical-align : top; }

	&.text-bottom { vertical-align : bottom; }

	&.text-middle { vertical-align : middle; }
}

th.table-cell-empty,
td.table-cell-empty {
	background-color : transparent !important;
	color            : transparent !important;
}*/

/* -----------------------------------------------------------------------------
 * Scrollbare Tabelle
 * -----------------------------------------------------------------------------
 * Tabelle ist scrollbar auf mobilen Geraeten bzw. auf kleineren Displays.
 * -------------------------------------------------------------------------- */

.table-scrollable {
	background-color : transparent;
	margin           : $table-default-margin;
	max-width        : $table-default-width;
	width            : $table-default-width;
	position         : relative;

	table { margin : 0; }

	//.table-scroll-icon {
	//	bottom      : -1em;
	//	color       : #9f9f9f;
	//	font-size   : .75em;
	//	left        : 50%;
	//	line-height : 1em;
	//	margin-left : -25px;
	//	position    : absolute;
	//	text-align  : center;
	//	width       : 50px;
	//}

	.table-scrollable-wrap {
		-webkit-overflow-scrolling : touch;
		overflow-y                 : hidden;
		overflow-x                 : auto;
	}
}

/* =========================================================================
 *
 * Layout: Fixed
 *
 * -------------------------------------------------------------------------
 * Alle Spalten haben dieselbe Breite.
 * ======================================================================

.table--fixed-layout { table-layout : fixed; }*/

/* =========================================================================
 *
 * Stil: Standard
 *
 * ======================================================================

.table-show-footer tfoot { display : table-row-group; }

.table th {
	background-color : map-get($tables, 'row-header-background-color');
	color            : map-get($tables, 'row-header-color');
}

.table th,
.table td { padding : 4px 8px; }

.table thead {
	th,
	td {
		background-color : map-get($tables, 'header-background-color');
		color            : map-get($tables, 'header-color');
		padding-bottom   : 8px;
		padding-top      : 8px;
		vertical-align   : top;
	}
}

.table tr.marked,
.table td.marked { background-color : #f1f1f1; }

.table tr.marked-medium,
.table td.marked-medium { background-color : #e4e4e4; }

.table tr.marked-high,
.table td.marked-high { background-color : #c5c5c5; }*/

/* =========================================================================
 *
 * Stil: mit Rahmen
 *
 * ======================================================================

.table--bordered { border : 1px solid #fff; }

.table--bordered {
	th,
	td {
		@extend .table--bordered;
	}
}*/

/* =========================================================================
 *
 * Stil: Tebra
 *
 * ======================================================================

.table--striped tbody tr:nth-child(even) { background-color : map-get($tables, 'background-color-even'); }

.table--striped tbody tr:nth-child(odd) { background-color : map-get($tables, 'background-color-odd'); }*/