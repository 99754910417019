/**
 * Navigation
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Hauptmenue
 *
 * ========================================================================== */

.nav-primary {
	$-item-margin-horizontal : $margin-base-horizontal;

	background-color : $nav-primary-bg-color;
	color            : $nav-primary-item-color;

	// (1)
	@if (variable-exists(nav-primary-font-family) and $nav-primary-font-family != '') {
		font-family : $nav-primary-font-family;
	}

	// (1)(2)
	@if (
		variable-exists(nav-primary-font-size) and
		type-of($nav-primary-font-size) == 'number' and
		unitless($nav-primary-font-size) == false and
		unit($nav-primary-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $nav-primary-font-size);
	}

	// (1)
	@if (variable-exists(nav-primary-font-style) and $nav-primary-font-style != '') {
		font-style : $nav-primary-font-style;
	}

	// (1)
	@if (variable-exists(nav-primary-text-transform) and $nav-primary-text-transform != '') {
		text-transform : $nav-primary-text-transform;
	}

	// (1)
	@if (variable-exists(nav-primary-font-variant) and $nav-primary-font-variant != '') {
		font-variant: $nav-primary-font-variant;
	}

	/*
	 * Hauptmenueebene.
	 */

	> ul {
		@include list-reset(ul, false, false);

		margin          : 0;
		align-content   : stretch;
		align-items     : stretch;
		display         : flex;
		flex-flow       : row wrap;
		justify-content : space-between;
		position        : relative;

		> li {
			align-items     : flex-end;
			display         : flex;
			flex            : 0 1 auto;
			justify-content : center;
			margin          : 0 $nav-primary-item-margin;
			padding         : 0;

			> a {
				background-color : $nav-primary-item-bg-color;
				color            : $nav-primary-item-color;
				display          : block;
				line-height      : 1;
				position         : static;

				// (1)
				@if (variable-exists(nav-primary-font-weight) and $nav-primary-font-weight != '') {
					font-weight : $nav-primary-font-weight;
				}

				&::before {
					border-top : 2px solid $color-white;
					content    : "";
					left       : 0;
					position   : absolute;
					right      : 0;
					top        : 0;
				}
			}

			&:hover > a,
			&.touch-hover-helper > a,
			> a:hover,
			> a:focus {
				background-color : $nav-primary-item-bg-color-hover;
				color            : $nav-primary-item-color-hover;
			}

			&.active > a {
				background-color : $nav-primary-item-bg-color-active !important;
				color            : $nav-primary-item-color-active !important;

				&::before { border-top-color : $nav-primary-item-color-active !important; }
			}

			// TODO(Heiko): Untermenü Primärnavigation deaktiviert.
			//&:hover > ul,
			//&.touch-hover-helper > ul { display : block; }

			&:first-child { margin-left : 0; }

			&:last-child {
				margin-right: 0;

				> ul {
					left  : auto;
					right : 0;
				}
			}
		}
	}

	/**
	 * Untermenueebene.
	 *

	> ul ul {
		background-color : $nav-primary-sub-bg-color;
		color            : $nav-primary-sub-item-color;
		display          : none;
		left             : 0;
		position         : absolute;
		text-align       : left;
		text-transform   : none;
		top              : 100%;
		width            : 260px;
		z-index          : 10; // Fix Position unterhalb des Hauptinhaltes!

		// (1)(2)
		@if (
			variable-exists(nav-primary-sub-font-size) and
			type-of($nav-primary-sub-font-size) == 'number' and
			unitless($nav-primary-sub-font-size) == false and
			unit($nav-primary-sub-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $nav-primary-sub-font-size);
		}

		// (1)
		@if (variable-exists(nav-primary-sub-font-style) and $nav-primary-sub-font-style != '') {
			font-style : $nav-primary-sub-font-style;
		}

		// (1)
		@if (variable-exists(nav-primary-sub-font-variant) and $nav-primary-sub-font-variant != '') {
			font-variant: $nav-primary-sub-font-variant;
		}

		// (1)
		@if (variable-exists(nav-primary-sub-text-transform) and $nav-primary-sub-text-transform != '') {
			text-transform : $nav-primary-sub-text-transform;
		}

		> li {
			border-top: 1px solid lighten($brand-primary, 4%);

			> a {
				background-color : $nav-primary-sub-item-bg-color;
				color            : $nav-primary-sub-item-color;
				display          : block;
				padding          : $padding-base-vertical-small $padding-base-horizontal;

				// (1)
				@if (variable-exists(nav-primary-sub-font-weight) and $nav-primary-sub-font-weight != '') {
					font-weight : $nav-primary-sub-font-weight;
				}
			}

			&:hover > a,
			> a:hover,
			> a:focus {
				background-color : $nav-primary-sub-item-bg-color-hover;
				color            : $nav-primary-sub-item-color-hover;
			}

			&.active > a {
				background-color : $nav-primary-sub-item-bg-color-active !important;
				color            : $nav-primary-sub-item-color-active !important;
			}
		}
	}*/
}

/* -----------------------------------------------------------------------------
 * Panel-Menue
 * -------------------------------------------------------------------------- */

.ppa-nav-primary .nav-primary > ul {
	display: block;

	> li {
		display : block;
		margin  : 0 0 $margin-base-vertical-xsmall;

		> a {
			background-color : transparent;
			color            : $color-white;
			line-height      : 1.25;
			padding          : $padding-base-vertical 0;

			&::before {
				border-top-color : transparent;
				max-width        : 50%;
			}
		}

		&:hover > a,
		&.touch-hover-helper > a,
		> a:hover,
		> a:focus {
			background-color : transparent;
			color            : rgba($color-white, 0.5);
		}

		&.active > a {
			background-color : transparent !important;
			color            : rgba($color-white, 0.5) !important;

			&::before { border-top-color : rgba($color-white, 0.5) !important; }
		}
	}
}

/* =============================================================================
 *
 * Service
 *
 * ========================================================================== */

.nav-service {
	@if (
		variable-exists(nav-service-font-size) and
		type-of($nav-service-font-size) == 'number' and
		unitless($nav-service-font-size) == false and
		unit($nav-service-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $nav-service-font-size);
	}

	@if (variable-exists(nav-service-text-transform)) {
		text-transform : $nav-service-text-transform;
	}

	> ul > li {
		display : inline-block;
		margin  : 0 $nav-service-item-margin;

/*		&:after {
			content     : '|';
			font-weight : $font-weight-normal;
			padding     : 0 $padding-base-horizontal-xsmall;
		}

		&:last-child:after { display : none; }*/

		a {
			background-color : $nav-service-item-bg-color;
			color            : $nav-service-item-color;

			@if (variable-exists(nav-service-font-weight)) {
				font-weight    : $nav-service-font-weight;
			}

			// Sind Hover- und Active-Farben gleich, dann fasse den Status
			// zusammen (weniger CSS).
			@if (
				$nav-service-item-bg-color-hover == $nav-service-item-bg-color-active and
				$nav-service-item-color-hover == $nav-service-item-color-active
			) {
				&:hover,
				&:focus,
				&:active,
				&.active {
					background-color : $nav-service-item-bg-color-hover;
					color            : $nav-service-item-color-hover;
				}
			} @else {
				&:hover,
				&:focus {
					background-color : $nav-service-item-bg-color-hover;
					color            : $nav-service-item-color-hover;
				}

				&:active,
				&.active {
					background-color : $nav-service-item-bg-color-active;
					color            : $nav-service-item-color-active;
				}
			}
		}

		&:last-child { margin-right : 0; }

		&:first-child { margin-left : 0; }
	}
}

/* =============================================================================
 *
 * Service
 *
 * ========================================================================== */

.nav-meta {
	// (1)
	@if (variable-exists(nav-meta-font-family) and $nav-meta-font-family != '') {
		font-family : $nav-meta-font-family;
	}

	@if (
		variable-exists(nav-meta-font-size) and
		type-of($nav-meta-font-size) == 'number' and
		unitless($nav-meta-font-size) == false and
		unit($nav-meta-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $nav-meta-font-size);
	}

	@if (variable-exists(nav-meta-text-transform)) {
		text-transform : $nav-meta-text-transform;
	}

	> ul > li {
		display : inline-block;
		margin  : 0 $nav-meta-item-margin;

/*		&:after {
			content     : '|';
			font-weight : $font-weight-normal;
			padding     : 0 $padding-base-horizontal-xsmall;
		}

		&:last-child:after { display : none; }*/

		a {
			background-color : $nav-meta-item-bg-color;
			color            : $nav-meta-item-color;

			@if (variable-exists(nav-meta-font-weight)) {
				font-weight    : $nav-meta-font-weight;
			}

			> i {
				font-size : .8125em;
				top       : -1px;
			}

			// Sind Hover- und Active-Farben gleich, dann fasse den Status
			// zusammen (weniger CSS).
			@if (
				$nav-meta-item-bg-color-hover == $nav-meta-item-bg-color-active and
				$nav-meta-item-color-hover == $nav-meta-item-color-active
			) {
				&:hover,
				&:focus,
				&:active,
				&.active {
					background-color : $nav-meta-item-bg-color-hover;
					color            : $nav-meta-item-color-hover;
				}
			} @else {
				&:hover,
				&:focus {
					background-color : $nav-meta-item-bg-color-hover;
					color            : $nav-meta-item-color-hover;
				}

				&:active,
				&.active {
					background-color : $nav-meta-item-bg-color-active;
					color            : $nav-meta-item-color-active;
				}
			}
		}

		&:last-child { margin-right : 0; }

		&:first-child { margin-left : 0; }
	}
}