/**
 * Startseite
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.view-index {

	.form-imas-search {
		.btn[data-imas-search="reset-filter"] { display : none; }
	}

	.section.widget > .widget-wrap {
		@extend .page-max-width;
	}

	.main > .section:last-child {
		padding-bottom : $margin-base-vertical-large;

		> .article { margin-bottom : 0; }
	}

}

@media print {

	.view-index .section.widget > .widget-wrap {
		max-width     : none;
		padding-left  : 0;
		padding-right : 0;
		width         : 100%;
	}

}