/**
 * Alerts (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.alert {
	margin  : $alert-margin;
	padding : $alert-padding;

	// (1)
	@if (
		variable-exists(alert-border) and
		$alert-border != ''
	) {
		border : $alert-border;
	}

	// (1)
	@if (
		variable-exists(alert-border-radius) and
		$alert-border-radius != ''
	) {
		border-radius : $alert-border-radius;
	}

	// (1)(2)
	@if (
		variable-exists(alert-font-size) and
		type-of($alert-font-size) == 'number' and
		unitless($alert-font-size) == false and
		unit($alert-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $alert-font-size);
	}

	// (1)
	@if (
		variable-exists(alert-font-weight) and
		$alert-font-weight != ''
	) {
		font-weight : $alert-font-weight;
	}

	.alert-link,
	a {
		// (1)
		@if (
			variable-exists(alert-link-font-weight) and
			$alert-link-font-weight != ''
		) {
			font-weight : $alert-link-font-weight;
		}

		// (1)
		@if (
			variable-exists(alert-link-text-decoration) and
			$alert-link-text-decoration != ''
		) {
			text-decoration : $alert-link-text-decoration;
		}
	}

	.close { display : none; }

	&-dismissible {
		padding-right : (nth($alert-padding, 1) + $padding-base-horizontal);

		.close { display : block; }
	}
}

.alert-type-list {
	> * {
		padding-left : 0;

		&::before { display : none; }
	}

	> :first-child { margin-top : 0; }

	> :last-child { margin-bottom : 0; }
}

.alert-primary { @include alert-variant($alert-primary-bg-color, $alert-primary-border-color, $alert-primary-color, $alert-primary-color-link);}

.alert-secondary { @include alert-variant($alert-secondary-bg-color, $alert-secondary-border-color, $alert-secondary-color, $alert-secondary-color-link);}

.alert-tertiary { @include alert-variant($alert-tertiary-bg-color, $alert-tertiary-border-color, $alert-tertiary-color, $alert-tertiary-color-link);}

.alert-success { @include alert-variant($alert-success-bg-color, $alert-success-border-color, $alert-success-color, $alert-success-color-link);}

.alert-info { @include alert-variant($alert-info-bg-color, $alert-info-border-color, $alert-info-color, $alert-info-color-link);}

.alert-warning { @include alert-variant($alert-warning-bg-color, $alert-warning-border-color, $alert-warning-color, $alert-warning-color-link);}

.alert-danger { @include alert-variant($alert-danger-bg-color, $alert-danger-border-color, $alert-danger-color, $alert-danger-color-link);}