/**
 * Tabs (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.tabs {
	border  : $tabs-container-border;
	padding : $tabs-container-padding;
}

.tabs .nav-tabs {
	border-bottom : 0 none;
	margin        : -10px -10px 0;

	> li {
		margin: 10px 10px -1px;

		> a {
			border          : 0 none;
			border-radius   : $tabs-border-radius;
			color           : $tabs-toggle-color;
			text-decoration : underline;

			&:hover {
				background-color : $tabs-toggle-bg-color-hover;
				border           : 0 none;
				color            : $tabs-toggle-color-hover;
				text-decoration  : none;
			}
		}

		&.active > a {
			&,
			&:hover,
			&:focus {
				background-color : $tabs-toggle-bg-color-active;
				border           : 0 none;
				color            : $tabs-toggle-color-active;
				cursor           : $cursor-default;
				text-decoration  : none;
			}
		}
	}
}

.tabs .tab-pane {
	h2 {
		color          : $brand-secondary;
		border         : 0 none;
		padding-bottom : 0;
	}

	&-inner {
		padding-top : $padding-base-vertical;

		> :first-child { margin-top : 0; }

		> :last-child { margin-bottom : 0; }
	}
}