/**
 * Stadtteilfinder: Ergebnis
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

$-df-spacing : ($padding-base-vertical-large + $padding-base-vertical);

*[data-districtfinder="results"] > .article-body {
	> *,
	.df-ranking-first > * { margin-top : $df-spacing-large; }

	.df-ranking-first {
		@extend .page-max-width;
		padding-top : $df-spacing-large;
	}

	.df-heading {
		text-align    : center;
		margin-bottom : 0;
	}

	.df-percent {
		border   : $df-r-percent-border-width $df-r-percent-border-style $df-r-percent-border-color;
		display  : block;
		position : relative;

		> * {
			display     : block;
			overflow    : hidden;
			padding     : 3px 10px;
			white-space : nowrap;
		}

		> .percent {
			background-color : $df-r-percent-bg-color;
			z-index          : 1;
		}

		> .text {
			bottom   : 0;
			color    : $df-r-percent-color;
			left     : 0;
			position : absolute;
			right    : 0;
			top      : 0;
			z-index  : 2;
		}
	}

	.df-map {
		&,
		> .googlemap { height : 100%; }

		> .googlemap { min-height : 400px; }
	}
}

*[data-districtfinder="results"] .df-ranking-other {
	background-color    : $brand-tertiary;
	background-position : top center;
	background-repeat   : no-repeat;
	background-size     : cover;
	color               : $color-white;
	padding-bottom      : 0;
	padding-top         : $-df-spacing;

	> h2 {
		@extend .page-max-width;

		color: $color-white;

		&::before { background-color : $color-white; }
	}

	> .gallery {
		.swiper-slide-wrapper {
			@extend .page-max-width;
		}

		.swiper-slide {
			.thumbnail {
				height: auto;

				> .image {
					height         : auto;
					padding-bottom : 390px;
					background-position: center;
				}
			}

			a {
				color : $color-white;

				&:hover,
				&:active,
				&:focus { color : $color-white; }
			}

			.row > * > * {
				&:first-child { margin-top : 0; }

				&:last-child { margin-bottom : 0; }
			}
		}

		.swiper-controls {
			$-offset : ceil($gallery-ctrl-size / 2);

			bottom   : -#{$-offset};
			left     : 0;
			position : absolute;
			right    : 0;

			> .page-max-width {
				> div {
					padding-right : 15px;
					text-align    : center;
					width         : 50%;
				}
			}
		}
	}
}

@include respond-to(sm) {
	*[data-districtfinder="results"] > .article-body .df-map {
		> .googlemap { min-height : 300px; }
	}

	*[data-districtfinder="results"] .df-ranking-other {
		padding-bottom : $-df-spacing;
	}

	*[data-districtfinder="results"] .df-ranking-other > .gallery {
		$-thumb-height : 480px;

		.swiper-slide {
			.thumbnail {
				margin-bottom : 50px !important;

				> .image { padding-bottom : $-thumb-height; }
			}

			h3 { margin-bottom : ($margin-base-vertical-large + $margin-base-vertical-small) !important; }
		}

		.swiper-controls {
			$-offset : $-thumb-height - ceil($gallery-ctrl-size / 2);

			bottom : auto;
			top    : #{$-offset};

			> .page-max-width {
				> div {
					padding-right : 0;
					width         : 100%;
				}
			}
		}
	}
}

@include respond-to(xs) {
	*[data-districtfinder="results"] {
		.df-map { height : 300px; }
	}

	*[data-districtfinder="results"] .df-ranking-other > .gallery {
		$-thumb-height : 420px;

		.swiper-slide {
			.thumbnail {
				> .image { padding-bottom : $-thumb-height; }
			}
		}

		.swiper-controls {
			$-offset : $-thumb-height - ceil($gallery-ctrl-size / 2);

			top : #{$-offset};
		}

		.df-map { height : 300px; }
	}
}

@include respond-to(c640) {
	*[data-districtfinder="results"] .df-ranking-other > .gallery {
		$-thumb-height : 330px;

		.swiper-slide {
			.thumbnail {
				> .image { padding-bottom : $-thumb-height; }
			}
		}

		.swiper-controls {
			$-offset : $-thumb-height - ceil($gallery-ctrl-size / 2);

			top : #{$-offset};
		}
	}
}

@include respond-to(c420) {
	*[data-districtfinder="results"] .df-ranking-other > .gallery {
		$-thumb-height : 190px;

		.swiper-slide {
			.thumbnail {
				> .image { padding-bottom : $-thumb-height; }
			}
		}

		.swiper-controls {
			$-offset : $-thumb-height - ceil($gallery-ctrl-size / 2);

			top : #{$-offset};
		}
	}
}

@media print {
	*[data-districtfinder="results"] {
		> .article-body .df-ranking-first,
		.df-ranking-other > h2,
		.df-ranking-other > .gallery .swiper-slide-wrapper {
			max-width     : none;
			padding-left  : 0;
			padding-right : 0;
			width         : 100%;
		}
	}
}