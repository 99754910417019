/**
 * IMAS
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.view-imas {
	.main-page { background-color : $color-gray-lighter; }
}