/**
 * Stadtteilfinder
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Konfigurationsimport.
 *
 * ========================================================================== */

@import '../../config/module/_districtfinder';

/* =============================================================================
 *
 * Global.
 *
 * ========================================================================== */

*[data-districtfinder] {
	/* Aktuelle Frage. */
	.df-heading {
		color         : $color-gray-dark;
		margin-bottom : ($margin-base-vertical-large * 2);
		margin-top    : 0;
	}
}

/* =============================================================================
 *
 * Partialimport
 *
 * ========================================================================== */

@import '_questions';
@import '_results';