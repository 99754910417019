/**
 * Spezielle, zugeschnitte Breakpoints.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

@include respond-to(c640) {

	.system-mq .custom640,
	.display-c640-block { display : block; }

	.hidden-c640 { display : none; }

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.checkbox-inline,
	.radio-inline {
		&.display-c640-block { margin-left : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_navs'
 	 * ---------------------------------------------------------------------- */

	/*
	 * Breadcrumb
	 */

	.nav-breadcrumb {
		> .title { display: none; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_videos-audio'
 	 * ---------------------------------------------------------------------- */

	.figure.video {
		&.left,
		&.right {
			float        : none;
			margin-left  : 0;
			margin-right : 0;
			width        : 100%;

			&.embed-responsive-16by9 { padding-bottom : 56.25%; }

			&.embed-responsive-4by3 { padding-bottom : 75%; }
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */

	$-showcase-height : 270px;

	.main-showcase {
		> .gallery[data-bind="gallery-showcase"] .swiper {
			&-pagination { bottom : 15px; }

			&-slide {
				&,
				&-wrapper { height : $-showcase-height; }
			}
		}
	}

	.view-index .main-showcase {
		height: auto;

		> .component {
			bottom   : auto;
			left     : auto;
			position : relative;
			right    : auto;
			top      : auto;

			> .component-wrap {
				padding-left  : 0;
				padding-right : 0;
			}
		}
	}

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

}

@include respond-to(c420) {

	.system-mq .custom420,
	.display-c420-block { display : block; }

	.hidden-c420 { display : none; }


	/* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ '../../vendors/plugins/slidebars/css/_slidebars.scss'
 	 * ---------------------------------------------------------------------- */

	.ppa { width : 240px; }

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.checkbox-inline,
	.radio-inline {
		&.display-c420-block { margin-left : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */

	/*
	 * Liste Spaltigkeit
	 */

	.list-col > li {
		clear : both;
		width : 100%;
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */

	/*
	 * Galerieliste
	 *

	.gallery-list {
		.item { width : 100%; }
	}*/

	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'components/_articles'
	 * ---------------------------------------------------------------------- */

	.article-box.property-box {
		.controls {

			> .rent {
				display : block;
				float   : none;
			}

			> .btn {
				display    : block;
				float      : none;
				margin-top : $margin-base-horizontal-small;
			}
		}
	}

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */

	.main-header {
		$-main-header-height : 88px;
		$-logo-target-height : 22px;

		> .main-header-wrap {
			height : $-main-header-height;

			> .nav { display : none; }
		}

		/**
		 * Logo
		 */

		.brand > span {
			$-logo-width      : calc-aspect-ratio-to-target($logo-origin-width, $logo-origin-height, 0, $-logo-target-height);
			$-logo-top-offset : ceil($-main-header-height / 2) - ceil($-logo-target-height / 2);

			padding-top     : $-logo-target-height; // Padding-Hack IE
			width           : #{$-logo-width}px;
		}

		/* Trigger */
		.trigger {
			$-trigger-height     : $-logo-target-height;
			$-trigger-top-offset : ceil($-main-header-height / 2) - ceil($-trigger-height / 2);

			&,
			> i {
				height      : $-trigger-height;
				line-height : $-trigger-height;
				width       : $-trigger-height;
			}

			font-size     : 1.25em;
			display       : block;
			padding-right : $-trigger-height;
			top           : $-trigger-top-offset;
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

}