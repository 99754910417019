/**
 * Galerien (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.gallery {
	@extend %clear-both;

	margin-bottom : $margin-base-vertical;
	margin-top    : $margin-base-vertical;
	position      : relative;

	.thumbnail {
		&,
		> .image {
			display  : block;
			margin   : 0;
			overflow : hidden;
			width    : 100%;

			> img { display : none; }
		}

		> .caption {
			background-color : rgba($color-black, 0.4);
			bottom           : 0;
			color            : $color-white;
			font-size        : cast-unit($font-unit-base, $font-size-small);
			font-style       : $font-style-normal;
			left             : 0;
			padding          : $padding-base-vertical-xsmall $padding-base-horizontal-small;
			position         : absolute;
			right            : 0;
		}
	}
}

.gallery .swiper- {
	&container {
		z-index : 1;

		&-fade .swiper-slide-prev { opacity : 0 !important; }
	}

	&wrapper { margin : 0; }

	&slide {
		@extend %transition-opacity;

		margin-bottom : 0;
		margin-top    : 0;
		padding       : 0;
		z-index       : 1;

		&::before { display : none; }

		&-active { z-index : 99; }
	}

	/* Blaettern */
	&pagination {
		left       : 15px;
		right      : 15px;
		text-align : right;
		top        : 10px;
		z-index    : 3;

		> .swiper-pagination-bullet {
			@extend %transition-background-color;

			background  : $color-gray-lighter;
			height      : 12px;
			line-height : 1;
			margin      : 0 5px;
			opacity     : 1;
			overflow    : hidden;
			text-indent : 150%;
			width       : 12px;

			&:hover,
			&:focus { background : $color-gray-light; }

			&:first-child { margin-left : 0; }

			&:last-child { margin-right : 0; }

			&-active { background : $brand-primary; }
		}
	}

	/* Vor-, Zurueck */
	&control {
		$-top-offset : ceil($gallery-ctrl-size / 2);

		@extend %transition;

		background-color    : $gallery-ctrl-bg-color;
		backface-visibility : hidden; /* HACK: Render-Visibility Chrome */
		border-radius       : $gallery-ctrl-border-radius;
		bottom              : 0;
		color               : $gallery-ctrl-color;
		cursor              : $cursor-pointer;
		display             : inline-block;
		margin-top          : -#{$-top-offset};
		position            : absolute;
		text-align          : center;
		top                 : 50%;
		z-index             : 5;

		// Schriftgroesse?
		@if (
			variable-exists(gallery-ctrl-font-size) and
			type-of($gallery-ctrl-font-size) == 'number' and
			unitless($gallery-ctrl-font-size) == false and
			unit($gallery-ctrl-font-size) == 'px'
		) {
			font-size        : cast-unit($font-unit-base, $gallery-ctrl-font-size);
		}

		&,
		&::before {
			height : $gallery-ctrl-size;
			width  : $gallery-ctrl-size;
		}

		&::before {
			@extend .fa-icon-helper;

			display     : block;
			font-size   : cast-unit($font-unit-base, $gallery-ctrl-font-size);
			line-height : $gallery-ctrl-size;
		}

		&.prev {
			left : 0;

			&::before {
				content       : $gallery-ctrl-prev-icon-content;
				padding-right : 3px;
			}
		}

		&.next {
			right : 0;

			&::before {
				content      : $gallery-ctrl-next-icon-content;
				padding-left : 3px;
			}
		}

		&.prev,
		&.next {
			&.swiper-button-disabled {
				cursor  : $cursor-disabled;
				opacity : .3;
			}
		}
	}

	/* Zähler */
	&count {
		background-color : $gallery-count-bg-color;
		color            : $gallery-count-color;
		height           : $gallery-count-size;
		left             : 0;
		line-height      : $gallery-count-size;
		overflow         : hidden;
		position         : absolute;
		text-align       : center;
		top              : 0;
		white-space      : nowrap;
		z-index          : 10;

		// Schriftgroesse?
		@if (
			variable-exists(gallery-count-font-size) and
			type-of($gallery-count-font-size) == 'number' and
			unitless($gallery-count-font-size) == false and
			unit($gallery-count-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $gallery-count-font-size);
		}

		// (1)
		@if (
			variable-exists(gallery-count-font-weight) and
			$gallery-count-font-weight != ''
		) {
			font-weight : $gallery-count-font-weight;
		}

		// (1)
		@if (
			variable-exists(gallery-count-padding) and
			$gallery-count-padding != ''
		) {
			padding : $gallery-count-padding;
		}
	}

	/* Wrapper-Container fuer die einzelnen Steuerelemente. */
	&controls {
		backface-visibility : hidden; /* HACK: Render-Visibility Chrome */
		z-index             : 2;

		/* Positionierung der Kontrollelemente zuruecksetzen. */
		.swiper-control {
			bottom        : auto;
			left          : auto;
			position      : relative;
			right         : auto;
			top           : auto;
			display       : inline-block;
			margin-bottom : auto;
			margin-top    : auto;
		}
	}
}

/* =============================================================================
 *
 * Galerie - Standard
 *
 * ========================================================================== */

.gallery[data-bind="gallery"] {
	.swiper- {
		&slide-wrapper {
			@extend %transition-opacity;

			overflow : hidden;
			position : relative;
			opacity  : 0;
		}

		&slide {
			.thumbnail {
				z-index : 1;

				&,
				> .image { height : 230px; }
			}
		}

		//&pagination {}

		//&control {}
	}

	// Initialisiert / Deaktiviert
	&.swiper-{
		&initialized .swiper-slide {
			.swiper-slide-wrapper { opacity : 1; }
		}

		&disabled .swiper- {
			&pagination,
			&control,
			&count { display: none !important;}
		}
	}
}

/* =============================================================================
 *
 * Galerie - Showcase
 *
 * ========================================================================== */

.gallery[data-bind="gallery-showcase"] {
	@extend .gallery[data-bind="gallery"];

	margin  : 0;
	padding : 0;
}

.gallery[data-bind="gallery-showcase"] .swiper-slide {
	// Hoehe wird ueber 'Showcase' gesetzt!

	.thumbnail {
		height  : 100%;
		z-index : 1;

		> .image {
			background-position : center;
			height              : 100%;
		}
	}

/*	.text {
		bottom      : 0;
		left        : 0;
		line-height : 1.25;
		position    : absolute;
		right       : 0;
		top         : 0;
		z-index     : 2;

		> p {
			@extend .page-max-width;
			height        : 100%;
			margin-bottom : 0;
			margin-top    : 0;
			position      : relative;
		}

		.text-wrap {
			left     : $padding-base-horizontal;
			position : absolute;
			right    : $padding-base-horizontal;
			top      : 28%;

			> * {
				background-color : $brand-secondary;
				color            : $color-white;
				display          : inline-block;
				font-family      : $font-family-secondary;
				font-weight      : $font-weight-bold;
				max-width        : 100%;
				padding          : 20px;

				text-transform: uppercase;
				font-size: cast-unit($font-unit-base, $h1-font-size);

				> em {
					display        : block;
					font-family    : $font-family-primary;
					font-size      : .8125em;
					font-style     : normal;
					font-weight    : $font-weight-light;
					text-transform : none;
				}

				> i { font-size : .9375em; }
			}

			> .link::before { display : none; }
		}
	}*/
}

/* =============================================================================
 *
 * Galerie - Thumbnails
 *
 * ========================================================================== *

.gallery-two-way-control {
	$-height : 540px;
	$-height-thumbnails : ceil($-height / 3);

	.swiper-slide-wrapper { height : 100%; }

	.text {
		bottom     : 0;
		left       : 0;
		position   : absolute;
		right      : 0;
		transition : border-color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
		z-index    : 2;

		> .caption {
			color      : $color-white;
			padding    : $padding-base-vertical $padding-base-horizontal;
			transition : color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;

			* {
				border-color : $color-white;
				color        : $color-white;
				font-weight  : $font-weight-bold;
				margin       : 0;
				transition   : color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
			}

			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size   : cast-unit($font-unit-base, $h3-font-size);
				font-weight : $font-weight-light;
			}

			> :first-child { margin-top : 0; }

			> :last-child { margin-bottom : 0; }
		}
	}

	.gallery {
		$-thumbs-width : 22%;

		&-view,
		&-thumbs {
			float  : left;
			height : $-height;
		}

		&-view { width : (100 - $-thumbs-width); }

		&-thumbs {
			width : $-thumbs-width;

			.swiper-slide {
				cursor  : $cursor-pointer;
				display : block;
				height  : $-height-thumbnails;
			}

			.text {
				top              : 0;
				background-color : rgba($brand-tertiary, 0.7);
				border           : 2px solid transparent;

				> .caption {
					bottom     : 0;
					left       : 0;
					position   : absolute;
					right      : 0;
					z-index    : 3;
				}
			}

			.active .text {
				border-color : $brand-secondary;

				> .caption,
				> .caption * { color : $brand-secondary; }
			}
		}
	}

}*&

/* =============================================================================
 *
 * Galerieliste
 *
 * ========================================================================== */

.gallery-list {
	margin-bottom : $margin-base-vertical;
	margin-top    : $margin-base-vertical;

	.thumbnail {
		display  : block;
		margin   : 0;
		overflow : hidden;
	}

	> .item {
		margin-bottom : $margin-base-vertical;
		margin-top    : $margin-base-vertical;
		padding-left  : $padding-base-horizontal;
		padding-right : $padding-base-horizontal;
		text-align    : center;

		&::before {
			content : '';
			display : none;
		}

		@extend %child-reset-margin-last-top;
	}
}