/**
 * Indicator (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

@keyframes rotate {
	0% { transform : rotate(0deg); }

	100% { transform : rotate(360deg); }
}

.indicator {
	position : absolute;
	height   : $indicator-size;

	&::after {
		animation          : rotate 0.8s infinite linear;
		border-color       : $indicator-border-color;
		border-radius      : 50%;
		border-right-color : transparent !important;
		border-style       : solid;
		content            : '';
		left               : 50%;
		position           : absolute;
		top                : 50%;
	}

	&::after,
	&-lg::after {
		$-pos-offset : ceil($indicator-size / 2);

		border-width : $indicator-border-width;
		height       : $indicator-size;
		margin       : (-#{$-pos-offset}) 0 0 (-#{$-pos-offset});
		width        : $indicator-size;
	}

	&-md {
		height       : $indicator-md-size;

		&::after {
			$-pos-offset : ceil($indicator-md-size / 2);
			border-width : $indicator-md-border-width;
			height       : $indicator-md-size;
			margin       : -#{$-pos-offset} 0 0 -#{$-pos-offset};
			width        : $indicator-md-size;
		}
	}

	&-sm {
		height       : $indicator-sm-size;

		&::after {
			$-pos-offset : ceil($indicator-sm-size / 2);
			border-width : $indicator-sm-border-width;
			height       : $indicator-sm-size;
			margin       : -#{$-pos-offset} 0 0 -#{$-pos-offset};
			width        : $indicator-sm-size;
		}
	}

	&-xs {
		height : $indicator-xs-size;

		&::after {
			$-pos-offset : ceil($indicator-xs-size / 2);
			border-width : $indicator-xs-border-width;
			height       : $indicator-xs-size;
			margin       : -#{$-pos-offset} 0 0 -#{$-pos-offset};
			width        : $indicator-xs-size;
		}
	}
}