/**
 * Visitenkarten (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.vcard {
	display: block;

	> * {
		float : left;
		width : 50%;
	}

	> .data {
		padding-left : $padding-base-vertical-large;

		> * {
			margin-bottom : $margin-base-vertical-small;
			margin-top    : $margin-base-vertical-small;
		}

		.name {
			color       : $brand-tertiary;
			font-size   : cast-unit($font-unit-base, $font-size-large);
			font-weight : $font-weight-bold;
		}

		.link-email {
			display    : block;
			margin-top : $margin-base-vertical;
		}

		> *:first-child { margin-top : 0; }

		> *:last-child { margin-bottom : 0; }
	}

}