/**
 * Navigation (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Standard
 *
 * ========================================================================== */

.nav {
	@extend %nav-list;
	@extend .clearfix;

	// (1)
	@if (variable-exists(nav-font-family) and $nav-font-family != '') {
		font-family : $nav-font-family;
	}

	// (1)(2)
	@if (
		variable-exists(nav-font-size) and
		type-of($nav-font-size) == 'number' and
		unitless($nav-font-size) == false and
		unit($nav-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $nav-font-size);
	}

	// (1)
	@if (variable-exists(nav-font-style) and $nav-font-style != '') {
		font-style : $nav-font-style;
	}

	// (1)
	@if (variable-exists(nav-text-transform) and $nav-text-transform != '') {
		text-transform : $nav-text-transform;
	}

	// (1)
	@if (variable-exists(nav-font-variant) and $nav-font-variant != '') {
		font-variant: $nav-font-variant;
	}

	a {
		display         : inline-block;
		text-decoration : none;
		position        : relative;

		// (1)
		@if (variable-exists(nav-font-weight) and $nav-font-weight != '') {
			font-weight : $nav-font-weight;
		}

		> i {
			padding-right : $padding-base-horizontal-small;
			position      : relative;
		}

		&:hover,
		&:active,
		&:focus { text-decoration : none; }
	}

	li {
		margin       : 0;
		padding-left : 0;
		position     : relative;

		&::before { display : none; }
	}
}

/* =============================================================================
 *
 * Breadcrumb
 *
 * ========================================================================== */

.nav-breadcrumb {
	* {
		clear   : none;
		display : inline;
	}

	> .title {
		@if (variable-exists(nav-breadcrumb-title-show) and $nav-breadcrumb-title-show == true) {
			color          : $nav-breadcrumb-title-color;
			display        : inline-block;
			font-size      : cast-unit($font-unit-base, $nav-breadcrumb-font-size);
			font-weight    : $nav-breadcrumb-title-font-weight;
			margin         : 0;
			opacity        : .4;
			padding-right  : 5px;
			text-transform : none;

			&::after { content : ':'; }
		} @else {
			display : none;
		}
	}

	li {
		display       : inline-block;
		opacity       : .4;
		padding-right : 5px;

		> * {
			color       : $nav-breadcrumb-color;
			font-weight : $nav-breadcrumb-font-weight;
			font-size   : cast-unit($font-unit-base, $nav-breadcrumb-font-size);
		}

		> a {
			color : $nav-breadcrumb-color;

			&::after {
				content      : '/';
				padding-left : 5px;
			}

			&:hover,
			&:focus,
			&.active {
				color : $nav-breadcrumb-color-hover;

				&::after { color : $nav-breadcrumb-color; }
			}
		}

		&.active { opacity : 1; }

		&:last-child {
			padding-right : 0;

			a::after { display : none; }
		}
	}
}

/* =============================================================================
 *
 * Step Wizard
 *
 * ========================================================================== */

//.nav-stepwizard {
//	font-size : cast-unit($font-unit-base, $nav-stepwizard-font-size);
//
//	> li {
//		display    : table-cell;
//		text-align : center;
//		width      : 1%;
//		z-index    : 1;
//
//		&:hover { z-index : 10; }
//
//		> a,
//		> span {
//			@if ($nav-stepwizard-border-width != null) {
//				border : $nav-stepwizard-border-width $nav-stepwizard-border-style $nav-stepwizard-border-color;
//			} @else {
//				border : 0 none;
//			}
//
//			background-color : $nav-stepwizard-bg-color;
//			border-radius    : $nav-stepwizard-border-radius;
//			color            : $nav-stepwizard-color;
//			display          : block;
//			padding          : $nav-stepwizard-padding;
//			z-index          : 1;
//		}
//
//		> span { cursor : $cursor-default; }
//
//		> a {
//			&:hover,
//			&:focus {
//				@if ($nav-stepwizard-border-width != null) {
//					border-color : $nav-stepwizard-border-color-hover;
//				} @else {
//					border: 0 none;
//				}
//
//				background-color : $nav-stepwizard-bg-color-hover;
//				color            : $nav-stepwizard-color-hover;
//			}
//		}
//
//		// Active state
//		// Active state
//		&.active {
//			z-index : 2;
//
//			> a {
//				&,
//				&:hover,
//				&:focus {
//					@if ($nav-stepwizard-border-width != null) {
//						border-color : $nav-stepwizard-border-color-hover;
//					} @else {
//						border       : 0 none;
//					}
//
//					background-color : $nav-stepwizard-bg-color-hover;
//					color            : $nav-stepwizard-color-hover;
//				}
//			}
//
//			> span {
//				@if ($nav-stepwizard-border-width != null) {
//					border-color : $nav-stepwizard-border-color-hover;
//				} @else {
//					border       : 0 none;
//				}
//
//				background-color : $nav-stepwizard-bg-color-hover;
//				color            : $nav-stepwizard-color-hover;
//			}
//		}
//
//		&.disabled { opacity : .4; }
//
//		@if ($nav-stepwizard-border-width != null and $nav-stepwizard-border-collapse == true) {
//			$-offset-margin : ceil($nav-stepwizard-border-width / 2);
//
//			> a,
//			> span {
//				margin-left  : -#{$-offset-margin};
//				margin-right : -#{$-offset-margin};
//			}
//
//			&:first-child > a { margin-left : 0; }
//
//			&:last-child > a { margin-right : 0; }
//		}
//	}
//}
