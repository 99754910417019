/**
 *
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2015, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: June 14, 2015
 */

/**
 * Cookiebar
 *
 * Informationshinweis ueber Cookienutzung
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.cookie-bar {
	$-btn-enable-height : 30px;
	$-btn-enable-height-offset : ceil($-btn-enable-height / 2);
	$-btn-enable-width : 120px;
	$-btn-enable-width-offset : ceil($-btn-enable-width / 2);

	color      : $color-white;
	transition : all 0.25s ease;

	&.is-fixed {
		bottom     : 0;
		left       : 0;
		padding    : ($padding-base-vertical-small * 2) ($padding-base-horizontal-small * 2);
		text-align : center;
		width      : 30%;
		z-index    : 9999;
	}

	&-inner {
		background : rgba($brand-primary, 0.9);
		padding    : $padding-base-vertical-small $padding-base-horizontal-small;
		position   : relative;

		> * {
			margin  : 0 0 $padding-base-vertical;
			padding : 0;
		}

		> :last-child {
			margin-bottom : 0;
		}
	}

	/* -------------------------------------------------------------------------
	 * Buttons
	 * ---------------------------------------------------------------------- */

	.btn {
		text-decoration : none;

		&-cb-enable {
			background-color : transparent;
			border           : 2px solid $color-white;
			color            : $color-white;
			display          : inline-block;
			font-size        : $font-size-xsmall;
			font-weight      : $body-font-weight;
			line-height      : 2;
			transition       : color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
			width            : $-btn-enable-width;

			&:hover,
			&:active,
			&:focus {
				background-color : $color-white;
				color            : $brand-primary;
				text-decoration  : none;
			}
		}

		// &-cb-disable {}

		// &-cb-policy {}
	}

	/* -------------------------------------------------------------------------
	 * Inhalt
	 * ---------------------------------------------------------------------- */

	p {
		font-size     : $font-size-xsmall;
		line-height   : $line-height-small;
		text-align    : center;

		> a {
			color           : $color-white;
			font-weight     : $body-font-weight;
			padding         : 2px 4px;
			text-decoration : underline;

			&:hover,
			&:active,
			&:focus {
				color: inherit;
			}
		}
	}
}
