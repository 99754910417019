/**
 * Rating stars
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.rating-stars {
	background-color : $rating-stars-bg-color;
	color            : $rating-stars-color;
	display : inline-block;

	> * {
		background-color : $rating-stars-star-bg-color;
		color            : $rating-stars-star-color;
		margin           : $rating-stars-star-margin;

		&:first-child { margin-left : 0; }

		&:last-child { margin-right : 0; }
	}
}
