/**
 * Vendors
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * @ '../../vendors/plugins/cookiebar/css/_cookiebar.scss'
 *
 * ========================================================================== */

@import '../../vendors/plugins/cookiebar/css/_cookiebar';

/**
	Cookie-Banner
**/
@import '../../vendors/plugins/ifab-cookie-banner/css/_ifab-cookie-banner.scss';
@import './_ifab-cookie-banner.scss';

.cookie-bar {
	&.is-fixed {
		@include respond-to(md) {
			width : 40%;
		}

		@include respond-to(sm) {
			width : 50%;
		}

		@include respond-to(xs) {
			bottom    : auto;
			left      : 50%;
			padding   : 0;
			right     : auto;
			top       : 88px;
			transform : translateX(-50%);
			width     : 70%;
		}
	}
}

/* =============================================================================
 *
 * @ '../../vendors/plugins/slidebars/css/_slidebars.scss'
 *
 * ========================================================================== */

@import '../../vendors/plugins/slidebars/css/_slidebars';

.ppa[off-canvas*="overlay"] { /* stylelint-disable-line string-quotes */
	z-index : 9001;
}

.ppa {
	width : 280px;

	&-blocker {
		@extend %transition-opacity;

		background-color    : rgba($brand-secondary, 0.8);
		bottom              : auto;
		height              : 1px;
		left                : 0;
		opacity             : 0;
		position            : absolute;
		right               : 0;
		top                 : -1px;
		transition-duration : 500ms;
		z-index             : 9001;

		&.is-open {
			bottom  : 0;
			height  : 100%;
			opacity : 1;
		}
	}

	&-close {
		$-close-height        : 22px;
		$-close-width         : $-close-height;
		$-close-stroke-width  : 3px;
		$-close-stroke-offset : ceil($-close-height / 2) - ceil($-close-stroke-width / 2);

		height      : $-close-height;
		overflow    : hidden;
		display     : none;
		position    : absolute;
		right       : ($padding-base-horizontal + 5);
		text-indent : 150%;
		top         : 0;
		white-space : nowrap;
		width       : $-close-width;
		z-index     : 10;

		&::before,
		&::after {
			width      : 100%;
			background : #fff;
			content    : '';
			display    : block;
			height     : $-close-stroke-width;
			left       : 0;
			overflow   : hidden;
			position   : absolute;
			right      : 0;
		}

		&::before {
			top       : $-close-stroke-offset;
			transform : rotate(45deg);
		}

		&::after {
			top       : $-close-stroke-offset;
			transform : rotate(-45deg);
		}
	}

	&-nav-primary {
		background-color : $brand-primary;

		> .nav {
			padding : $main-header-height ($padding-base-horizontal + 5) ($padding-base-vertical + 5);
		}
	}
}

/* stylelint-disable string-quotes */
[class*="ppa-do-open"],
[class*="ppa-do-toggle"],
[class*="ppa-do-close"] { cursor : pointer; }
/* stylelint-enable */

/* =============================================================================
 *
 * @ '_iscroll.scss'
 *
 * ========================================================================== */

// @import '../../vendors/plugins/iscroll/css/_iscroll';

/* =============================================================================
 *
 * @ '_swiper.scss'
 *
 * ========================================================================== */

@import '../../vendors/plugins/swiper/css/_swiper';

/* =============================================================================
 *
 * @ '_pageflip.scss'
 *
 * ========================================================================== */

// @import '../../vendors/plugins/pageflip/css/_pageflip';

/* =============================================================================
 *
 * @ '_bootstrap-select.scss'
 *
 * ========================================================================== */

@import '../../vendors/plugins/bootstrap-select/css/_bootstrap-select';

.bootstrap-select {
	z-index: 2;

	// &.open > .btn.dropdown-toggle {
	//	background-color : $input-bg-color-focus;
	//	border-color     : $input-border-color-focus;
	//	color            : $input-color-focus;
	// }

	> .dropdown-toggle.bs-placeholder {
		&,
		&:hover,
		&:focus,
		&:active { color : $color-white; }
	}

	&.btn-group {
		.dropdown-toggle .caret {
			right      : 10px;
			margin-top : -10px;
		}

		.dropdown-menu {
			z-index : 11;

			&.inner { overflow-x : hidden; }

			li {
				a.opt { padding : $dropdown-menuitem-padding; }

				&.selected a {
					&,
					&:hover,
					&:active,
					&:focus {
						background-color : $dropdown-menuitem-bg-color-hover;
						color            : $dropdown-menuitem-color-hover;
					}
				}
			}
		}
	}

	&.show-tick .dropdown-menu li {
		a {
			padding-left : $padding-base-horizontal-large + 10;

			span.check-mark {
				margin-top : 2px;
				right      : auto;
				left       : 5px;
			}
		}
	}
}

.bs-searchbox {
	padding : 0;

	.form-control {
		border-left         : 0 none;
		border-right        : 0 none;
		border-top          : 0 none;
		border-bottom-width : 1px;
	}
}

/* =============================================================================
 *
 * @ '_ion-rangeslider.scss'
 *
 * ========================================================================== */

@import '../../vendors/plugins/ion-rangeslider/css/_ion-rangeslider';

$irs-height         : $input-height;
$irs-bar-height     : 4px;
$irs-trigger-height : 24px;

.irs {
	$-irs-trigger-top-offset : ($irs-height - $irs-trigger-height);
	$-irs-bar-top-offset     : ($-irs-trigger-top-offset + ceil($irs-trigger-height / 2) - ceil($irs-bar-height / 2));

	height : $irs-height;

	// &-with-grid { height : 75px; }

	// &-grid { height : 27px; }

	&-line {
		background         : $color-white;
		height             : $irs-bar-height;
		top                : $-irs-bar-top-offset;

		> * { height : $irs-bar-height; }

		// &-left {}

		// &-mid {}

		// &-right {}
	}

	&-bar {
		background-color : $btn-primary-bg-color;
		height           : $irs-bar-height;
		top              : $-irs-bar-top-offset;

		// &-edge {
		//	background    : #428bca;
		//	border-radius : 16px 0 0 16px;
		//	border-right  : 0;
		//	height        : 10px; top : 33px;
		//	width         : 14px;
		// }
	}

	// &-shadow {
	//	height             : 2px; top : 38px;
	//	background         : #000;
	//	opacity            : 0.3;
	//	border-radius      : 5px;
	//	-moz-border-radius : 5px;
	//
	//	@at-root .lt-ie9 & {
	//		filter : alpha(opacity=30);
	//	}
	// }

	&-slider {
		background-color : $btn-primary-bg-color;
		border-radius    : 100%;
		cursor           : pointer;
		height           : $irs-trigger-height;
		top              : $-irs-trigger-top-offset;
		width            : $irs-trigger-height;

		&.state_hover,
		&:hover { background : $btn-primary-bg-color-hover; }
	}

	&-min,
	&-max {
		background    : rgba(0, 0, 0, 0.1);
		border-radius : 3px;
		color         : #333;
		font-size     : 12px;
		line-height   : 1.333;
		padding       : 1px 5px;
		text-shadow   : none;
		top           : 0;

		@at-root .lt-ie9 & {
			background : #ccc;
		}
	}

	&-from,
	&-to,
	&-single {
		background    : #428bca;
		border-radius : 3px;
		color         : #fff;
		font-size     : 14px;
		line-height   : 1.333;
		padding       : 1px 5px;
		text-shadow   : none;

		@at-root .lt-ie9 & {
			background : #999;
		}
	}
}

/* =============================================================================
 *
 * @ '_sweet-alert.scss'
 *
 * ========================================================================== */

@import '../../vendors/plugins/sweet-alert/css/_sweet-alert';

.sweet {
	&-overlay { background-color : rgba(89, 83, 83, 0.5); }

	&-alert {
		background-color : $color-white;
		padding          : $padding-base-vertical-large;

		> .message { margin : 0 0 20px; }

		.button-bar > * {
			margin : 0 10px;

			&:first-child { margin-left : 0; }

			&:last-child { margin-right : 0; }
		}
	}

	&-alert-danger {
		color            : $color-white;
		background-color : $brand-danger;

		> h2 {
			color     : #fff;
			font-size : 1.3125em;
			margin    : -10px -10px 20px;
			padding   : 10px;
		}

		> .message { color : $color-white; }
	}
}

@include respond-to(c420) {
	.sweet-alert {
		padding : $padding-base-vertical;

		.button-bar > * {
			margin  : 0;
			float   : left;
			height  : auto;
			padding : $padding-base-vertical-xsmall $padding-base-vertical-small;

			&:last-child { float : right; }
		}
	}
}

/* =============================================================================
 *
 * @ '_magnific-popup.scss'
 *
 * ========================================================================== */

@import '../../vendors/plugins/magnific-popup/css/_magnific-popup';

.mfp {
	&-bg {
		background-color : $modal-backdrop-bg-color;
		opacity          : 1;
	}

	&-figure {
		$-vertical-offset : 60px;
		$-border-width    : 10px;

		.mfp {
			&-bottom-bar { margin-top : -($-vertical-offset - $-border-width); }

			&-counter { padding-top : $-border-width; }
		}

		&:after { /* stylelint-disable-line selector-pseudo-element-colon-notation */
			background-color : $modal-bg-color;
			border-radius    : $modal-border-radius;
			bottom           : $-vertical-offset;
			box-shadow       : $modal-box-shadow;
			margin           : -$-border-width;
			top              : $-vertical-offset;
		}

		> figure {
			> img {
				border-radius    : $modal-border-radius;
				margin           : 0;
				padding          : $-vertical-offset 0;
			}

			// > figcaption { display : none; }
		}

		/*
		> .mpf-close {
			background-color : #fff;
			border           : 0 none;
			bottom           : auto;
			color            : #c63434;
			display          : block;
			font-size        : 21px;
			height           : 30px;
			left             : auto;
			margin-top       : 0;
			opacity          : 1;
			padding          : 0;
			position         : absolute;
			right            : 0;
			top              : 0;
			width            : 30px;
			z-index          : 10;
		}
		*/
	}
}

button.mfp {
	&-arrow {
		$-size      : 50px;
		$-size-half : ceil($-size / 2);

		background-color : transparent;
		opacity          : 1;

		&:active { margin-top : -54px; }

		&::before { display : none; }

		&::after {
			@extend .fa-icon-helper;

			background-color : $brand-primary;
			border           : 0 none;
			color            : $color-white;
			height           : $-size;
			line-height      : $-size;
			margin           : -#{$-size-half} 0 0;
			text-align       : center;
			top              : 50%;
			width            : $-size;

			@if (
				variable-exists(gallery-ctrl-font-size) and
				type-of($gallery-ctrl-font-size) == 'number' and
				unitless($gallery-ctrl-font-size) == false and
				unit($gallery-ctrl-font-size) == 'px'
			) {
				font-size : cast-unit($font-unit-base, $gallery-ctrl-font-size);
			}
		}

		&-left {
			left : $margin-base-horizontal-large;

			&::after { content : $gallery-ctrl-prev-icon-content; }
		}

		&-right {
			right : $margin-base-horizontal-large;

			&::after { content : $gallery-ctrl-next-icon-content; }
		}

		//	height           : $-size;
		//	opacity          : 1;
		//	top              : 50%;
		//	margin-top       : -#{$-size-half};
		//	width            : $-size;
		//
		//	&::before { display : none; }
		//
		//	&::after {
		//		@extend .fa-icon-helper;
		//
		//		border      : 0 none;
		//		color       : $color-white;
		//		height      : $-size;
		//		left        : 0;
		//		line-height : $-size;
		//		margin      : 0;
		//		opacity     : 1;
		//		text-align  : center;
		//		top         : 0;
		//		width       : $-size;
		//
		//		@if (
		//			variable-exists(gallery-ctrl-font-size) and
		//			type-of($gallery-ctrl-font-size) == 'number' and
		//			unitless($gallery-ctrl-font-size) == false and
		//			unit($gallery-ctrl-font-size) == 'px'
		//		) {
		//			font-size : cast-unit($font-unit-base, $gallery-ctrl-font-size);
		//		}
		//	}
		//
		//	&-left {
		//		left : $margin-base-horizontal-large;
		//
		//		&::after { content : $gallery-ctrl-prev-icon-content; }
		//	}
		//
		//	&-right {
		//		right : $margin-base-horizontal-large;
		//
		//		&::after { content : $gallery-ctrl-next-icon-content; }
		//	}
	}
}
