/**
 * Panels (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package Workspace
 */

.panel {
	margin-bottom    : $line-height-computed;
	background-color : $panel-bg-color;
	border           : $panel-border-width $panel-border-style transparent;
	border-radius    : $panel-border-radius;
	box-shadow       : none;

	&-body {
		@extend %child-reset-margin-last-top;

		color   : $panel-body-color;
		padding : $panel-body-padding;
	}

	&-heading {
		@extend %child-reset-margin-last-top;

		background-color : $panel-heading-bg-color;
		border-bottom    : $panel-border-width $panel-border-style transparent;

		@if ($panel-border-radius == 0) {
			border-radius : $panel-border-radius;
		} @else {
			border-top-left-radius  : $panel-border-radius - 1;
			border-top-right-radius : $panel-border-radius - 1;
		}

		font-size : cast-unit($font-unit-base, $panel-heading-font-size);
		padding   : $panel-heading-padding;

	}

	&-title {
		font-size : inherit;
/*		color     : inherit;

		> a,
		> small,
		> .small,
		> small > a,
		> .small > a {
			color : inherit;
		}*/
	}

	&-footer {
		@extend %child-reset-margin-last-top;

		background-color : $panel-footer-bg-color;
		border-top       : $panel-border-width $panel-border-style transparent;
		color            : $panel-footer-color;
		padding          : $panel-footer-padding;

		@if ($panel-border-radius == 0) {
			border-radius : $panel-border-radius;
		} @else {
			border-bottom-left-radius  : $panel-border-radius - 1;
			border-bottom-right-radius : $panel-border-radius - 1;
		}
	}
}

/* -----------------------------------------------------------------------------
 * Collapsable panels (aka, accordion)
 * -----------------------------------------------------------------------------
 * Wrap a series of panels in `.panel-group` to turn them into an accordion with
 * the help of our collapse JavaScript plugin.
 * -------------------------------------------------------------------------- */

.panel-group {
	.panel { border-radius : $panel-border-radius; }

	.panel-heading {
		border-bottom : 0;

		+ .panel-collapse > .panel-body,
		+ .panel-collapse > .list-group { border-top : $panel-border-width $panel-border-style transparent; }
	}

	.panel-footer {
		border-top : 0;

		+ .panel-collapse .panel-body { border-bottom : $panel-border-width $panel-border-style transparent; }
	}
}

/* -----------------------------------------------------------------------------
 * Kontextabhaengige Varianten.
 * -------------------------------------------------------------------------- */

.panel-default { @include panel-variant($panel-default-border-color, $panel-default-color, $panel-default-bg-color); }

.panel-primary { @include panel-variant($panel-primary-border-color, $panel-primary-color, $panel-primary-bg-color); }

.panel-secondary { @include panel-variant($panel-secondary-border-color, $panel-secondary-color, $panel-secondary-bg-color); }

.panel-tertiary { @include panel-variant($panel-tertiary-border-color, $panel-tertiary-color, $panel-tertiary-bg-color); }

.panel-success { @include panel-variant($panel-success-border-color, $panel-success-color, $panel-success-bg-color); }

.panel-info { @include panel-variant($panel-info-border-color, $panel-info-color, $panel-info-bg-color); }

.panel-warning { @include panel-variant($panel-warning-border-color, $panel-warning-color, $panel-warning-bg-color); }

.panel-danger { @include panel-variant($panel-danger-border-color, $panel-danger-color, $panel-danger-bg-color); }