/**
 * Small Devices, Tablets.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

@include respond-to(sm) {

	.system-mq .sm,
	.display-sm-block { display : block; }

	/* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ '../../vendors/plugins/slidebars/css/_slidebars.scss'
 	 * ---------------------------------------------------------------------- */

	.ppa {
		&-close {
			display     : block;
			top         : 0;
		}

		&-nav-primary {
			> .ppa-close {
				top: 33px;
			}

			> .nav {
				padding-top: 88px;
			}
		}
	}

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.checkbox-inline,
	.radio-inline {
		&.display-sm-block { margin-left : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ----------------------------------------------------------------------

	.gallery[data-bind="gallery-showcase"] .swiper-slide {}*/

	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */

	//.googlemap:not(responsive-16by9):not(responsive-4by3):not(responsive-3by4):not(responsive-3by2):not(responsive-2by3) { height : 400px; }
	.googlemap:not([class*="responsive-"]) { height : 400px; }

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_typo'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */

	.main-header {
		$-main-header-height : 88px;
		$-logo-target-height : 30px;

		> .main-header-wrap {
			height : $-main-header-height;

			> .nav { display : none; }
		}

		/**
		 * Logo
		 */

		.brand > span {
			$-logo-width      : calc-aspect-ratio-to-target($logo-origin-width, $logo-origin-height, 0, $-logo-target-height);
			$-logo-top-offset : ceil($-main-header-height / 2) - ceil($-logo-target-height / 2);

			padding-top     : $-logo-target-height; // Padding-Hack IE
			width           : #{$-logo-width}px;
		}

		/* Trigger */
		.trigger {
			$-trigger-height     : $-logo-target-height;
			$-trigger-top-offset : ceil($-main-header-height / 2) - ceil($-trigger-height / 2);

			&,
			> i {
				height      : $-trigger-height;
				line-height : $-trigger-height;
				width       : $-trigger-height;
			}

			font-size     : 1.5em;
			display       : block;
			padding-right : $-trigger-height;
			top           : $-trigger-top-offset;
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	.main-footer {
		text-align:center;

		.meta ul {
			margin-top : -#{$margin-base-vertical-small};

			li { margin-top : $margin-base-vertical-small; }
		}

		.social {
			text-align  : center;
			margin-top : $margin-base-vertical;
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */

	$-showcase-height : 460px;

	.main-showcase {
		> .gallery[data-bind="gallery-showcase"] .swiper-slide {
			&,
			&-wrapper { height : $-showcase-height; }
		}
	}

	.view-index .main-showcase { height : $-showcase-height; }

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

}