/**
 * Grundaufbau, -raster
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package Workspace
 */

html {
	-moz-osx-font-smoothing  : grayscale;
	-ms-text-size-adjust     : 100%;
	-webkit-text-size-adjust : 100%;
	-webkit-font-smoothing   : antialiased;
}

body,
html {
	height           : 100%;
	margin           : 0;
	padding          : 0;
}

body {
	color            : $body-text-color;
	font             : $body-font-style $body-font-variant $body-font-weight #{$body-font-size}/#{$line-height-base} $body-font-family;
	//letter-spacing   : -.0625em;
	text-align       : $body-text-align;
}

.show-on-print { display : none; }

/* -----------------------------------------------------------------------------
 * Grid-Varianten
 * -------------------------------------------------------------------------- */

//@include grid-gutter-variant('md', 60px);

/* =============================================================================
 *
 * Grundcontainer der Seite
 *
 * ========================================================================== */

.page-max-width {
	$-padding-offset : ($padding-base-horizontal + $padding-base-horizontal-xsmall );

	margin-left   : auto;
	margin-right  : auto;
	max-width     : $website-max-width;
	padding-left  : $-padding-offset;
	padding-right : $-padding-offset;
	width         : $website-default-width;
}

.main-header {
	background : $main-header-bg-color;
	z-index    : 10;

	&-wrap { @extend .page-max-width; }
}

.main-navigation { background-color : $brand-secondary; }

.main-showcase { z-index : 1; }

.main-footer {
	@extend .page-max-width;

	background-color : $main-footer-bg-color;
	color            : $main-footer-color;
	margin-top       : ($margin-base-vertical-large * 2);
	z-index          : 5;
}

.main-breadcrumb {
	$_padding_inner : ($padding-base-vertical + $padding-base-vertical-large);

	background : $main-page-bg-color url(../images/bg-breadcrumb.jpg) no-repeat top center;
	background-size: cover;

	&-wrap {
		background-color : $main-breadcrumb-bg-color;
	}

	.nav-breadcrumb {
		@extend .page-max-width;

		padding-bottom : $_padding_inner;
		padding-top    : $_padding_inner;
	}

	&.is-empty {
		.main-breadcrumb-wrap {
			padding-bottom : $_padding_inner;
			padding-top    : $_padding_inner;
			font-size      : 0;

			> * { display : none; }
		}
	}
}

.main {
	z-index : 2;

	> * {
		&:first-child,
		> :first-child { margin-top : 0; }

		&:last-child,
		> :last-child { margin-bottom : 0; }
	}

	> .section:last-child { padding-bottom : 0; }
}

/* =============================================================================
 *
 * Sektionen, Bereiche
 *
 * ========================================================================== */

.main > .section {
	$-section-padding : ($margin-base-vertical-large * 2);

	background-position : top center;
	background-repeat   : no-repeat;
	background-size     : 100% auto;
	overflow            : hidden;
	padding-bottom      : $-section-padding;
	padding-top         : $-section-padding;

	> * { @extend .page-max-width; }

	> .title {
		color      : $color-gray-dark;
		display    : block;
		text-align : center;
	}

	/**
	 * Da jeder Artikel aus einem Grid besteht, bringt er auch ein eigenes
	 * "margin-bottom" mit was wiederum mit einem Elternelement "<section/>
	 * kollidiert. Daher wird hier ein negativer "margin-bottom" angegeben" um
	 * die Darstellung entsprechend anzupassen.
	 */
	> .article { margin-bottom : -#{$margin-base-vertical-large}; }

	/**
	 * Graue Sektion
	 */

	&-gray {
		background-color : $color-gray-lighter;
		color            : $color-gray-dark;
		padding-bottom   : $-section-padding !important;
		padding-top      : $-section-padding !important;

		h1,
		h2 {
			color: $color-gray-dark;

			&::before { background-color : $color-gray-dark; }
		}
	}

	&-dark {
		background-color : $color-gray-darker;
		color            : $brand-primary;
		font-weight      : $font-weight-normal;
		padding-bottom   : $-section-padding !important;
		padding-top      : $-section-padding !important;

		h1,
		h2 {
			color : $brand-primary;

			&::before { background-color : $brand-primary; }
		}
	}

	// Stadtteilfinder-Container.
	&-district-finder {
		overflow    : visible;
		padding-top : 0;

		> .article {
			margin-bottom : 0;
			max-width     : none;
			padding-left  : 0;
			padding-right : 0;
			width         : 100%;

			> .article-header > * { @extend .page-max-width; }

			> .article-header {
				background-color : $color-gray-lighter;
				color            : $color-gray-dark;
				padding-bottom   : $-section-padding !important;
				padding-top      : $-section-padding !important;

				> .description {
					color       : $brand-primary;
					font-size   : cast-unit($font-unit-base, $font-size-large);
					font-weight : $font-weight-bold;
					text-align  : center;
				}
			}
		}
	}

	// Dunkler Balken mit Text und Link zum Stadtteilfinder.
	&-find-district {
		$-padding : ($padding-base-vertical-large + $padding-base-vertical);

		padding-bottom : $-padding !important;
		padding-top    : $-padding !important;

		.article {
			margin-bottom : 0;

			.article-body.row > * {
				margin-top    : 0;
				margin-bottom : 0;
			}
		}
	}
}

/* =============================================================================
 *
 * Info ueber aktuellen Mediaquery.
 *
 * ========================================================================== */

@if ($debug-mode) {
	.system-mq {
		background-color : #c00;
		color            : #fff;
		font-size        : 10px;
		opacity          : .6;
		padding          : 2px 5px;
		position         : fixed;
		right            : 0;
		text-transform   : uppercase;
		top              : 0;
		z-index          : 99999;

		span { display : none; }
	}
} @else {
	.system-mq { display : none; }
}