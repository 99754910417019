/**
 * Transitions.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

%transition { transition : all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

%transition-color { transition : color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

%transition-colors {
	transition : background-color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
	             border-color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
	             color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

%transition-background-color { transition : background-color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

%transition-border-color { transition : border-color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

%transition-height { transition : height 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

%transition-opacity { transition : opacity 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

%transition-width { transition : width 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

%transition-size {
	transition : height 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
	             width 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}