/**
 * Aktuelles
 *
 * Darstellung Immobilieneintrag, Newsmeldung
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.widget-news {
	.widget-title { margin-bottom : $margin-base-vertical-small; }

	.article-box { margin-top : $margin-base-vertical-large; }
}

//@include respond-to(sm) {}

//@include respond-to(xs) {}

//@include respond-to(c640) {}

//@include respond-to(c420) {}