/**
 * Willkommen
 *
 * Integration eines Artikeldetails
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

//.widget-welcome {}

//@include respond-to(sm) {}

//@include respond-to(xs) {}

//@include respond-to(c640) {}

//@include respond-to(c420) {}