/**
 * Showcase
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package Workspace
 */

.main-showcase {
	$-height                : 800px;
	$-gallery-bottom-offset : ($margin-base-vertical-large + $margin-base-vertical);

	background-color    : $color-gray-light;
	background-position : top center;
	background-repeat   : no-repeat;
	background-size     : cover;
	padding             : $padding-base-vertical-large 0 ($padding-base-vertical + $input-height);
	position            : relative;

	//> * { z-index : 1; }

	> .gallery[data-bind="gallery-showcase"] .swiper {
		&-pagination {
			bottom     : $-gallery-bottom-offset;
			left       : 0;
			right      : 0;
			text-align : center;
			top        : auto;
		}

		&-slide {
			&,
			&-wrapper { height : $-height; }
		}
	}

	> .component {
		z-index: 30;

		> .component-wrap {
			@extend .page-max-width;

			max-width : 830px;
		}
	}

	@at-root .view-index & {
		height  : $-height;
		padding : 0;

		> .component {
			backface-visibility : hidden; /* Fix Render-Visibility Chrome */
			bottom              : ($-gallery-bottom-offset * 3);
			left                : 0;
			position            : absolute;
			right               : 0;
		}
	}
}
