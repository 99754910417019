/**
 * Schrifteneinbindung
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
*
* @ Fira Sans von Google
*
* ========================================================================== */

/* fira-sans-300 - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-300.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Light'), local('FiraSans-Light'),
	url('../fonts/fira-sans/fira-sans-v8-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-300.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-300italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 300;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-300italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'),
	url('../fonts/fira-sans/fira-sans-v8-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-300italic.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-300italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-regular - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Regular'), local('FiraSans-Regular'),
	url('../fonts/fira-sans/fira-sans-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Italic'), local('FiraSans-Italic'),
	url('../fonts/fira-sans/fira-sans-v8-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-italic.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-500 - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-500.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Medium'), local('FiraSans-Medium'),
	url('../fonts/fira-sans/fira-sans-v8-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-500.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-500.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-500italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 500;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-500italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Medium Italic'), local('FiraSans-MediumItalic'),
	url('../fonts/fira-sans/fira-sans-v8-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-500italic.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-500italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-700 - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Bold'), local('FiraSans-Bold'),
	url('../fonts/fira-sans/fira-sans-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-700.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-700.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-700italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 700;
	src: url('../fonts/fira-sans/fira-sans-v8-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
	url('../fonts/fira-sans/fira-sans-v8-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/fira-sans/fira-sans-v8-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-700italic.woff') format('woff'), /* Modern Browsers */
	url('../fonts/fira-sans/fira-sans-v8-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/fira-sans/fira-sans-v8-latin-700italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
