/**
 * Artikel (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.article {
	position : relative;

	.title {
		margin-top : 0;
		overflow   : hidden;
	}

	> .article- {
		&header {
			> .description { margin-bottom : 0; }
		}

		&footer {
			display : none;
		}

		&header,
		&footer {
			> .datetime { display : none; }
		}
	}

	> .article-body {
		> .article-body-section {
			margin-bottom : ($article-body-section-margin * 2);
			margin-top    : ($article-body-section-margin * 2);

			> :first-child { margin-top : 0; }

			> :last-child { margin-bottom : 0; }
		}

		&.row {
			> .article-body-section {
				margin-bottom : $article-body-section-margin;
				margin-top    : $article-body-section-margin;
			}
		}
	}

	/**
	 * Abstand des Grid's innerhalb eines Artikels anpassen wenn kein
	 * Artikeltitel oder -kopf existiert (oder per ".hidden" ausgeblendet).
	 *

	> .title.hidden,
	> .article-header.hidden {
		& + .article-body.row { margin-top : -#{$margin-base-vertical-large}; }
	}*/

	/*
	 * Abstaende (Margin) in bezug auf erste und letzte Kindelemente anpassen.
	 */

	> :first-child,
	> .article-header > :first-child,
	> .article-body > :first-child,
	> .article-body .article-body-section-wrap > :first-child,
	> .article-footer > :first-child { margin-top : 0; }

	> :last-child,
	> .article-header > :last-child,
	> .article-body > :last-child,
	> .article-body .article-body-section-wrap > :last-child,
	> .article-footer > :last-child { margin-bottom : 0; }
}

.article-box {
	$-padding-offset  : $padding-base-horizontal-large;

	&-wrap {
		background-color : $color-white;
		height           : 100%;
		padding          : $-padding-offset;
		position         : relative;

		> * {
			margin : 0 0 $margin-base-vertical;

			&:last-child { margin-bottom : 0; }
		}
	}

	.datetime { display : none; }

	.thumbnail {
		display      : block;
		margin-left  : -#{$-padding-offset};
		margin-right : -#{$-padding-offset};
		margin-top   : -#{$-padding-offset};
		width        : auto;

		> .label {
			background-color : rgba($brand-secondary, 0.8);
			border-radius    : 0;
			bottom           : 0;
			font-size        : cast-unit($font-unit-base, $font-size-small);
			font-weight      : $font-weight-light;
			left             : 0;
			padding          : $padding-base-vertical-xsmall $padding-base-vertical-small;
			position         : absolute;
			z-index          : 2;
		}
	}

	.title {
		font-size      : cast-unit($font-unit-base, $h3-font-size);
		font-weight    : $h3-font-weight;
		color          : $h3-color !important;
		padding        : 0;
		text-transform : none;

		&::before { display : none; }
	}

	.description { color : $brand-secondary; }

	ul.description {
		align-content   : stretch;
		align-items     : stretch;
		border-top      : 1px solid $color-gray-light;
		display         : flex;
		flex-flow       : row wrap;
		justify-content : space-between;
		margin-bottom   : 0;
		padding-top     : $padding-base-vertical;

		> li {
			align-items     : center;
			display         : flex;
			flex            : 0 1 auto;
			justify-content : center;
			position        : relative;
			text-align      : center;

			&:first-child { text-align : left; }

			&:last-child { text-align : right; }
		}
	}

	&.property-box {
		$-height-offset : $input-height + $padding-base-vertical;

		> .article-box-wrap { padding-bottom : $-height-offset; }

		address {
			font-size  : cast-unit($font-unit-base, $font-size-large);
			font-style : $font-style-italic;
			text-align : center;
		}

		.controls {
			$-bottom-offset : ceil($input-height / 2);
			bottom     : -#{$-bottom-offset};
			left       : 0;
			min-height : $input-height;
			min-width  : 170px;
			position   : absolute;
			right      : 0;
			text-align : center;

			> .btn { min-width : 170px; }
		}
	}
}