/**
 * Navigationen
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

%nav-list {
	ul,
	ol {
		border     : 0 none;
		list-style : none;
		margin     : 0;
		padding    : 0;
	}

	> ul > li,
	> li,
	> li li {
		background-image : none;
		border           : 0 none;
		margin           : 0;
		padding          : 0;
		position         : relative;
	}

	a {
		display          : inline-block;
		position         : relative;
		text-decoration  : none;
	}
}