/**
 * Animationen (Keyframes).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Dropdown - Fade in
 *
 * ========================================================================== */

@keyframes dropdown-fade-in {
	from {
		opacity : 0;
		//top     : 120%;
	}
	to {
		opacity : 1;
		//top     : 100%;
	}
}

/* =============================================================================
 *
 * Drehen
 *
 * ========================================================================== */

@keyframes link-spin {
	0% { transform : rotate(0deg); }
	100% { transform : rotate(359deg); }
}