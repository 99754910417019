#ifab-cookie-banner.ifab-cb {
	animation        : none;
	background-color : $brand-primary;
	bottom           : 0;
	font-size        : 1.6rem;
	font-weight      : normal;
	left             : auto;
	max-height       : 100vh;
	max-width        : 60rem;
	overflow-y       : auto;
	right            : 0;
	top              : auto;
	width            : 60rem;

	p {
		margin : 0;
	}

	> * {
		margin : 0;

		+ * {
			margin-top : 1rem;
		}
	}

	@include respond-to(md) {
		max-width : none;
	}

	@include respond-to(sm) {
		max-width : 100%;
		font-size : 0.75rem;
	}

	@include respond-to(xs) {
		padding   : 1rem;
		font-size : 0.75rem;

		> * {
			+ * {
				margin-top : 0.75rem;
			}
		}
	}

	@media only screen and (orientation : landscape) {
		max-height : 75vh;
	}
}

#ifab-cookie-options-button-container {
	//display : none;
}

#ifab-cookie-law-link-container {
	text-align : center;
}

#ifab-cookie-options-button {
	color : #fff;
}

#ifab-cookie-options-button:hover {
	//border : 3px solid white;
}

#ifab-cookie-options-button-icon {
	font-weight : 300;
}

#ifab-cookie-options-container label {
	color       : white;
	font-weight : bold;
}

#ifab-cookie-options-container input {
	left   : 0;
	height : 22px;
	width  : 22px;
}

#ifab-cookie-option-list-container {
	margin : 5px 0;
}

.ifab-cookie-option-text-container p {
	margin   : 0;
	overflow : hidden;
}

#ifab-cookie-law-link {
	margin-left     : 5px;
	text-decoration : underline;
}

#ifab-cookie-accept-button, #ifab-cookie-decline-button {
	border-radius : 50px;
	color         : #fff;
	margin        : 0 auto;
}

#ifab-cookie-accept-button.hide, #ifab-cookie-decline-button.hide {
	display : none;
}

#ifab-cookie-show-banner-button,
#ifab-cookie-decline-button,
#ifab-cookie-accept-button {
	@include respond-to(sm) {
		font-size : 0.75rem;
	}
}

#ifab-cookie-submit-container {
	display         : flex;
	justify-content : space-between;
	@include respond-to(sm) {
		flex-wrap : wrap;

		button {
			flex-basis : 100%;

			+ button {
				margin-top: 1rem;
			}
		}
	}
}

.ifab-cookie-custom-checkmark {
	background-color : $color-white;
	border-color     : $color-white;
}

.ifab-cookie-option-container {
	padding-left: 10px;

	label {
		@include respond-to(sm) {
			font-size : 0.75rem;
		}
	}

	input :disabled ~ .ifab-cookie-custom-checkmark {
		background-color : #8aaad1;
		border-color     : #8aaad1;

		& :after {
			border-color : $brand-secondary;
		}
	}

	.ifab-cookie-custom-checkmark :after {
		left         : 5px;
		bottom       : 2px;
		width        : 7px;
		height       : 15px;
		border       : solid $brand-primary;
		border-width : 0 3px 3px 0;
		transform    : rotate(45deg);
	}
}
