/**
 * Widgets (Global, Import)
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Globale Defintionen.
 *
 * ========================================================================== */

.widget {
	padding : 0;

	> .widget-wrap {
		> :first-child { margin-top : 0; }

		> :last-child { margin-bottom : 0; }
	}

	.article > .datetime { display : none; }
}

/* =============================================================================
 *
 * Widgetimport.
 *
 * ========================================================================== */

@import '_welcome';
@import '_news';