/**
 * Formulare (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

input,
legend { font-family: $font-family-base; }

legend,
fieldset .legend {
	color          : $legend-color;
	display        : none;
	font-size      : cast-unit($font-unit-base, $legend-font-size);
	margin         : $legend-margin;
	padding        : $legend-padding;
	position       : relative;
	text-align     : center;
	text-transform : uppercase;
	width          : 100%;
}

fieldset {
	background-color : $fieldset-bg-color;
	margin           : $fieldset-margin;
	padding          : $fieldset-padding;
	position         : relative;
	//	&:nth-last-of-type(1) { margin-bottom : 0; }

/*	> :first-child { margin-top : 0; }

	> :last-child { margin-bottom : 0; }*/

	@at-root .fieldset-row {
		margin : $fieldset-margin;

		fieldset { margin-bottom : 0; }

		> :nth-child(1),
		> :nth-child(2) {
			fieldset { margin-top : 0; }
		}
	}
}

form {
	margin : $form-margin 0;

	> :first-child { margin-top : 0; }

	> :last-child { margin-bottom : 0; }
}

label,
.checkbox label,
.radio label {
	color       : $label-color;
	font-weight : $label-font-weight;
	font-style  : $label-font-style;
}

input,
textarea,
button,
.btn {
	outline-style : none;
	outline-width : 0;
}

/*input,
input[type="search"],
input[type="file"],
input.input-fileupload,
select,
textarea { -webkit-appearance : none; }*/

/*
 * Buttons bei Nummernfeldern entfernen.
 */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance : none;
	margin             : 0;
}

input[type=number] { -moz-appearance : textfield; }

.help-block {
	color     : $brand-info;
	font-size : cast-unit($font-unit-base, $font-size-xsmall);
	font-weight: $font-weight-light;
}

/* -----------------------------------------------------------------------------
 * Form control (Bootstrap)
 * -------------------------------------------------------------------------- */

.form-control {
	background-color : $input-bg-color;
	border           : $input-border-width $input-border-style $input-border-color;
	border-radius    : $comp-border-radius-base;
	box-shadow       : $input-box-shadow;
	color            : $input-color;
	font-size        : $input-font-size;
	font-style       : $input-font-style;
	font-weight      : $input-font-weight;
	height           : $input-height;
	line-height      : $line-height-base;
	padding          : $comp-padding-vertical-base $comp-padding-horizontal-base;

	@include form-control-event(hover, $input-color-hover, $input-bg-color-hover, $input-border-color-hover, false);

	@include form-control-event(focus, $input-color-focus, $input-bg-color, $input-border-color-focus, true);

	&.is-danger { @include form-control-validation-is-state($state-danger-color, $input-bg-color, $state-danger-border-color); }

	&.is-info { @include form-control-validation-is-state($state-info-color, $input-bg-color, $state-info-border-color); }

	&.is-invalid { @include form-control-validation-is-state($state-invalid-color, $input-bg-color, $state-invalid-border-color); }

	&.is-success { @include form-control-validation-is-state($state-success-color, $input-bg-color, $state-success-border-color); }

	&.is-valid { @include form-control-validation-is-state($state-valid-color, $input-bg-color, $state-valid-border-color); }

	&.is-warning { @include form-control-validation-is-state($state-warning-color, $input-bg-color, $state-warning-border-color); }

	&-reset {
		border        : 0 none;
		border-radius : 0;
		font-size     : inherit;
		height        : auto;
		line-height   : normal;
		padding       : 0;
	}

	&-static { margin-top : 0; }

	&-hint {
		display   : none;
		font-size : cast-unit($font-unit-base, $font-size-small);
	}
}

.form-control[readonly],
fieldset[readonly] .form-control {
	background-color : $input-bg-color-readonly;
	border-color     : $input-border-color-readonly;
	color            : $input-color-readonly;

	@if (variable-exists(input-opacity-readonly)) {
		opacity : $input-opacity-readonly;
	}
}

.form-control[disabled],
fieldset[disabled] .form-control {
	background-color : $input-bg-color-disabled;
	border-color     : $input-border-color-disabled;
	color            : $input-color-disabled;

	@if (variable-exists(input-opacity-disabled)) {
		opacity : $input-opacity-disabled;
	}
}

/* -----------------------------------------------------------------------------
 * Eingabefelder
 * -------------------------------------------------------------------------- */

textarea {
	min-height : 120px;
	// Safari fix
	overflow   : auto;
	resize     : vertical;
}

//input[type="search"] {}

/* Set the height of file controls to match text inputs. */
input[type="file"] { display : block; }

/* Make range inputs behave like textual form controls. */
input[type="range"] {
	display : block;
	width   : 100%;
}

/* -----------------------------------------------------------------------------
 * Auswahlfelder
 * -------------------------------------------------------------------------- */

/*
 * Checkboxen, Radio
 * Better position for radios and checkboxes.
 */

input {
	&.radio
	&.radio,
	&[type="radio"],
	&.checkbox,
	&[type="checkbox"] {
		background-color   : transparent;
		border             : 0 none;
		box-shadow         : none;
		cursor             : pointer;
		height             : auto;
		line-height        : normal;
		padding            : 0;
		width              : auto;
	}

	&.checkbox,
	&[type="checkbox"] { -webkit-appearance : checkbox; }

	&.radio,
	&[type="radio"] { -webkit-appearance : radio; }
}

/*
 * Select
 */

select {
	cursor : pointer;

	&[multiple],
	&[size] { height : auto; }

	> option[disabled] { color : $input-color-disabled; }

	//&[data-bind*="selectpicker"] { visibility : hidden; }
}

/* -----------------------------------------------------------------------------
 * Status
 * -------------------------------------------------------------------------- */

.has-danger { @include form-control-validation($state-danger-color, $input-bg-color, $state-danger-border-color); }

.has-info { @include form-control-validation($state-info-color, $input-bg-color, $state-info-border-color); }

.has-invalid { @include form-control-validation($state-invalid-color, $input-bg-color, $state-invalid-border-color); }

.has-success { @include form-control-validation($state-success-color, $input-bg-color, $state-success-border-color); }

.has-valid { @include form-control-validation($state-valid-color, $input-bg-color, $state-valid-border-color); }

.has-warning { @include form-control-validation($state-warning-color, $input-bg-color, $state-warning-border-color); }

/* -----------------------------------------------------------------------------
 * Input group
 * -------------------------------------------------------------------------- */

.input-group-addon {
	background-color : $input-group-addon-bg-color;
	border           : $input-group-addon-border-width $input-group-addon-border-style $input-group-addon-border-color;
	border-radius    : $comp-border-radius-base;
	color            : $input-group-addon-color;
	font-size        : $input-group-addon-font-size;
	font-weight      : $input-group-addon-font-weight;
	padding          : $comp-padding-vertical-base $comp-padding-horizontal-base;

	&.input-sm {
		border-radius : $input-border-radius-small;
		font-size     : $font-size-small;
		padding       : $comp-padding-vertical-small $comp-padding-horizontal-small;
	}

	&.input-lg {
		border-radius : $input-border-radius-large;
		font-size     : $font-size-large;
		padding       : $comp-padding-vertical-large $comp-padding-horizontal-large;
	}
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
	border-top-right-radius    : 0;
	border-bottom-right-radius : 0;
}

.input-group-addon:first-child { border-right : 0; }

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
	border-top-left-radius    : 0;
	border-bottom-left-radius : 0;
}

.input-group-addon:last-child { border-left : 0; }

/* -----------------------------------------------------------------------------
 * Platzhalter
 * -----------------------------------------------------------------------------
 * Selektoren muessen einzeln aufgefuehrt werden. Versteht ein Browser eine
 * Regel nicht, wuerde er automatisch auch alle anderen Regeln ignorieren.
 * -------------------------------------------------------------------------- */

/*input[placeholder] {
	color   : #979696;
	opacity : 1;
}

input[placeholder]:focus { color : #000; }*/

*::-webkit-input-placeholder {
	color       : $form-placeholder-color !important;
	font-style  : $form-placeholder-font-style !important;
	opacity     : 1;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder { color : $form-placeholder-color; }

*::-moz-placeholder {
	color       : $form-placeholder-color !important;
	font-style  : $form-placeholder-font-style !important;
	opacity     : 1;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder { color : $form-placeholder-color !important; }

:-moz-placeholder {
	color       : $form-placeholder-color !important;
	font-style  : $form-placeholder-font-style !important;
	opacity     : 1;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder { color : $form-placeholder-color !important; }

:-ms-input-placeholder {
	color       : $form-placeholder-color !important;
	font-style  : $form-placeholder-font-style !important;
	opacity     : 1;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder { color : $form-placeholder-color !important; }

/* =============================================================================
 *
 * Abweichende Formulardefintionen
 *
 * -----------------------------------------------------------------------------
 * Defintionen von abweichenden Komponenteneigenschaften.
 * ========================================================================== */

.form-group.has-rangeslider {
	position: relative;

	> label {
		left       : 15px;
		position   : absolute;
		right      : 15px;
		text-align : center;
		top        : 0;
	}
}

.form-control-datepicker {
	$-icon-size        : 30px;
	$-icon-offset-y    : ceil($-icon-size / 2);
	$-control-offset-x : ($-icon-size + $comp-padding-horizontal-base * 1.5);

	position: relative;

	> * { z-index : 1; }

	&::after {
		@extend .fa-icon-helper;

		content     : '\f073';
		font-size   : 1.25em;
		height      : $-icon-size;
		left        : $comp-padding-horizontal-base;
		line-height : $-icon-size;
		margin-top  : -#{$-icon-offset-y};
		position    : absolute;
		text-align  : center;
		top         : 50%;
		width       : $-icon-size;
		z-index     : 2;
	}

	.form-control { padding-left : $-control-offset-x; }

	&.icon-right {
		padding-left  : $comp-padding-horizontal-base;
		padding-right : $-control-offset-x;

		&::after {
			left  : auto;
			right : $comp-padding-horizontal-base;
		}
	}

	@at-root {
		.form-control-datepicker[disabled],
		fieldset[disabled] .form-control-datepicker {
			cursor : $cursor-not-allowed;

			&::after {
				color   : $input-color-disabled;
				opacity : .3;
			}
		}
	}
}

.form .captcha img {
	height     : auto;
	max-height : $input-height;
	max-width  : 100%;
	width      : auto;
}

.form-submit-ctrl {
	.step-delimiter { display : none; }

	> .ctrl {
		text-align : center;

		&-back { text-align : left; }

		&-next { text-align : right; }

		> * {
			margin : 0 $margin-base-horizontal-small;

			&:first-child { margin-left : 0; }

			&:last-child { margin-right : 0; }

			&:first-child:last-child {
				margin-left  : 0;
				margin-right : 0;
			}
		}
	}
}