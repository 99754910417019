/**
 * IMAS
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Konfigurationsimport.
 *
 * ========================================================================== */

//@import '../../config/module/_imas';

/* =============================================================================
 *
 * Partialimport
 *
 * ========================================================================== */

@import '_elements';
@import '_list';
@import '_detail';