/**
 * SweetAlert
 *
 * github.com/t4t5/sweetalert
 *
 * Copyright 2014, Tristan Edwards
 *
 * Licensed under MIT
 */

@keyframes showSweetAlert {
	0% { transform : scale(0.7); }

	45% { transform : scale(1.05); }

	80% { transform : scale(0.95); }

	100% { transform : scale(1); }
}

@keyframes hideSweetAlert {
	0% { transform : scale(1); }

	100% { transform : scale(0.5); }
}

.showSweetAlert { animation : showSweetAlert 0.3s; }

.hideSweetAlert { animation : hideSweetAlert 0.2s; }

@keyframes animateSuccessTip {
	0% {
		left  : 1px;
		top   : 19px;
		width : 0;
	}
	54% {
		left  : 1px;
		top   : 19px;
		width : 0;
	}

	70% {
		left  : -8px;
		top   : 37px;
		width : 50px;
	}

	84% {
		left  : 21px;
		top   : 48px;
		width : 17px;
	}

	100% {
		left  : 14px;
		top   : 45px;
		width : 25px;
	}
}

@keyframes animateSuccessLong {
	0% {
		right : 46px;
		top   : 54px;
		width : 0;
	}

	65% {
		right : 46px;
		top   : 54px;
		width : 0;
	}

	84% {
		right : 0;
		top   : 35px;
		width : 55px;
	}

	100% {
		right : 8px;
		top   : 38px;
		width : 47px;
	}
}

@keyframes rotatePlaceholder {
	0% { transform : rotate(-45deg); }

	5% { transform : rotate(-45deg); }

	12% { transform : rotate(-405deg); }

	100% { transform : rotate(-405deg); }
}

.animateSuccessTip { animation : animateSuccessTip 0.75s; }

.animateSuccessLong { animation : animateSuccessLong 0.75s; }

.icon.success.animate::after { animation : rotatePlaceholder 4.25s ease-in; }

@keyframes animateErrorIcon {
	0% {
		opacity   : 0;
		transform : rotateX(100deg);
	}
	100% {
		opacity   : 1;
		transform : rotateX(0deg);
	}
}

.animateErrorIcon { animation : animateErrorIcon 0.5s; }

@keyframes animateXMark {
	0% {
		margin-top : 26px;
		opacity    : 0;
		transform  : scale(0.4);
	}

	50% {
		margin-top : 26px;
		opacity    : 0;
		transform  : scale(0.4);
	}

	80% {
		margin-top : -6px;
		transform  : scale(1.15);
	}

	100% {
		margin-top : 0;
		opacity    : 1;
		transform  : scale(1);
	}
}

.animateXMark { animation : animateXMark 0.5s; }

@keyframes pulseWarning {
	0% { border-color : #f8d486; }

	100% { border-color : #f8bb86; }
}

.pulseWarning { animation : pulseWarning 0.75s infinite alternate; }

@keyframes pulseWarningIns {
	0% { background-color : #f8d486; }

	100% { background-color : #f8bb86; }
}

.pulseWarningIns { animation : pulseWarningIns 0.75s infinite alternate; }

.sweet {
	&-overlay {
		background-color : rgba(255, 255, 255, 0.8);
		bottom           : 0;
		display          : none;
		left             : 0;
		position         : fixed;
		right            : 0;
		top              : 0;
		z-index          : 99995;
	}

	&-alert {
		background-color : #fff;
		border-radius    : 0;
		display          : none;
		left             : 50%;
		margin-left      : -200px;
		margin-top       : -200px;
		overflow         : hidden;
		padding          : 10px;
		position         : fixed;
		text-align       : center;
		top              : 50%;
		width            : 400px;
		z-index          : 99996;

		> :first-child { margin-top : 0; }

		> :last-child { margin-bottom : 0; }
	}
}


@media all and (max-width : 767px) {
	.sweet-alert {
		width        : auto;
		margin-left  : 0;
		margin-right : 0;
		left         : 15px;
		right        : 15px;
	}
}

.sweet-alert .icon {
	border        : 4px solid gray;
	border-radius : 50%;
	box-sizing    : content-box;
	display       : none !important; /* Icons werden nicht genutzt! */
	height        : 80px;
	margin        : 20px auto;
	position      : relative;
	width         : 80px;
}

.sweet-alert .icon.error {
	border-color : #d43f3a;

	.x-mark { position : relative; display : block; }

	.line {
		position         : absolute;
		height           : 5px;
		width            : 47px;
		background-color : #d9534f;
		display          : block;
		top              : 37px;
		border-radius    : 2px;

		&.left {
			left      : 17px;
			transform : rotate(45deg);
		}

		&.right {
			right     : 16px;
			transform : rotate(-45deg);
		}
	}
}

.sweet-alert .icon.warning {
	border-color : #eea236;

	.body {
		background-color : #f0ad4e;
		border-radius    : 2px;
		height           : 47px;
		left             : 50%;
		margin-left      : -2px;
		position         : absolute;
		top              : 10px;
		width            : 5px;
	}

	.dot {
		background-color : #f0ad4e;
		border-radius    : 50%;
		bottom           : 10px;
		height           : 7px;
		left             : 50%;
		margin-left      : -3px;
		position         : absolute;
		width            : 7px;
	}
}

.sweet-alert .icon.info {
	border-color : #46b8da;

	&::before {
		content          : "";
		position         : absolute;
		width            : 5px;
		height           : 29px;
		left             : 50%;
		bottom           : 17px;
		border-radius    : 2px;
		margin-left      : -2px;
		background-color : #5bc0de;
	}

	&::after {
		content          : "";
		position         : absolute;
		width            : 7px;
		height           : 7px;
		border-radius    : 50%;
		margin-left      : -3px;
		top              : 19px;
		background-color : #5bc0de;
	}
}

.sweet-alert .icon.success {
	border-color : #4cae4c;

	&::before,
	&::after {
		background    : white;
		border-radius : 50%;
		content       : '';
		height        : 120px;
		position      : absolute;
		transform     : rotate(45deg);
		width         : 60px;
	}

	&::before {
		border-radius    : 120px 0 0 120px;
		left             : -33px;
		top              : -7px;
		transform        : rotate(-45deg);
		transform-origin : 60px 60px;
	}

	&::after {
		border-radius    : 0 120px 120px 0;
		left             : 30px;
		top              : -11px;
		transform        : rotate(-45deg);
		transform-origin : 0px 60px;
	}

	.placeholder {
		border        : 4px solid rgba(92, 184, 92, 0.2);
		border-radius : 50%;
		box-sizing    : content-box;
		height        : 80px;
		left          : -4px;
		position      : absolute;
		top           : -4px;
		width         : 80px;
		z-index       : 2;
	}

	.fix {
		background-color : #fff;
		height           : 90px;
		left             : 28px;
		position         : absolute;
		top              : 8px;
		transform        : rotate(-45deg);
		width            : 5px;
		z-index          : 1;
	}

	.line {
		background-color : #5cb85c;
		border-radius    : 2px;
		display          : block;
		height           : 5px;
		position         : absolute;
		z-index          : 2;

		&.tip {
			left      : 14px;
			top       : 46px;
			transform : rotate(45deg);
			width     : 25px;
		}

		&.long {
			right     : 8px;
			top       : 38px;
			transform : rotate(-45deg);
			width     : 47px;
		}
	}
}

.sweet-alert .icon.custom {
	background-position : center center;
	background-repeat   : no-repeat;
	background-size     : contain;
	border              : none;
	border-radius       : 0;
}

.sweet-alert .btn {
	&-default:focus {
	border-color : #ccc;
	box-shadow   : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(204, 204, 204, 0.6);
	outline      : 0;
	}

	&-success:focus {
		border-color : #4cae4c;
		box-shadow   : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(76, 174, 76, 0.6);
		outline      : 0;
	}

	&-info:focus {
		border-color : #46b8da;
		box-shadow   : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(70, 184, 218, 0.6);
		outline      : 0;
	}

	&-danger:focus {
		border-color : #d43f3a;
		box-shadow   : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(212, 63, 58, 0.6);
		outline      : 0;
	}

	&-warning:focus {
		border-color : #eea236;
		box-shadow   : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(238, 162, 54, 0.6);
		outline      : 0;
	}
}

.sweet-alert button::-moz-focus-inner { border : 0; }