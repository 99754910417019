#ifab-cookie-banner {
	animation           : fadein 0.5s;
	animation-fill-mode : forwards;
	background-color    : $brand-primary;
	box-sizing          : border-box;
	color               : #fff;
	max-width           : 500px;
	padding             : 1.5rem;
	position            : fixed;
	width               : 35rem;
	z-index             : 999;
}

@keyframes fadein {
	from {
		opacity : 0;
	}
	to {
		opacity : 1;
	}
}

@keyframes fadeout {
	from {
		opacity : 1;
	}
	to {
		opacity : 0;
	}
}


.ifab-cookie-top-center {
	top       : 1px;
	left      : 50%;
	transform : translateX(-50%);
}

.ifab-cookie-bottom-center {
	bottom    : 1px;
	left      : 50%;
	transform : translateX(-50%);
}

.ifab-cookie-left-center {
	left      : 1px;
	top       : 50%;
	transform : translateY(-50%);
}

.ifab-cookie-right-center {
	right     : 1px;
	top       : 50%;
	transform : translateY(-50%);
}

.ifab-cookie-top-left-corner {
	top  : 1px;
	left : 1px;
}

.ifab-cookie-top-right-corner {
	top   : 1px;
	right : 1px;
}

.ifab-cookie-bottom-left-corner {
	bottom : 1px;
	left   : 1px;
}

.ifab-cookie-bottom-right-corner {
	bottom : 1px;
	right  : 1px;
}

#ifab-cookie-image-container {
	text-align : center;
}

#ifab-cookie-image {
	max-width : 300px;
}

#ifab-cookie-description-container {
	margin : 0.5rem 0;
}

#ifab-cookie-description-text-container {
	margin-bottom : 0.5rem;
}

#ifab-cookie-law-link {
	color : white;
}

#ifab-cookie-options-button-container {
	text-align : center;
}

#ifab-cookie-options-button {
	//border        : none;
	//border-radius : 0;
	//border-bottom : 1px dashed white;
	box-shadow : none !important;
	display    : inline-block;
	width      : auto;
}

#ifab-cookie-options-button:hover {
	//color            : white;
	//background-color : transparent;
	//border-bottom    : 1px solid white;
}

.ifab-cookie-icon-margin-left {
	margin-left : 0.75rem;
}

.ifab-cookie-icon-margin-right {
	margin-right : 0.75rem;
}

#ifab-cookie-option-list-container {
	animation-fill-mode : forwards;
	display             : block;
	margin-bottom       : 5px;
	opacity             : 1;
	overflow            : hidden;
	padding             : 0;
	text-align          : center;
}

@keyframes showOptions {
	from {
		max-height : 0;
	}
	to {
		max-height : 100%;
	}
}

@keyframes hideOptions {
	from {
		max-height : 100%;
	}
	to {
		max-height : 0;
	}
}

.ifab-cookie-option-container {
	display        : flex;
	flex-direction : row;
	margin         : $margin-base-horizontal-small 0;
	position       : relative;
	text-align     : left;

	&:first-child {
		margin-top : 0;
	}

	&:last-child {
		margin-bottom : 0;
	}
}

.ifab-cookie-option-container input {
	display    : block;
	position   : absolute;
	cursor     : pointer;
	opacity    : 0;
	height     : 22px;
	width      : 22px;
	margin-top : 3px;
	z-index    : 10;
}

.ifab-cookie-custom-checkmark {
	position         : absolute;
	top              : 7px;
	left             : 0;
	height           : 22px;
	width            : 22px;
	border           : solid 3px white;
	background-color : $color-white;
}

/* When the checkbox is checked, add a blue background */
.ifab-cookie-option-container input:checked ~ .ifab-cookie-custom-checkmark {
	background-color : white;
}

/* When the checkbox is checked, add a blue background */
.ifab-cookie-option-container input:disabled ~ .ifab-cookie-custom-checkmark {
	background-color : #979797;
	border-color     : #979797;
}

.ifab-cookie-option-container input:disabled ~ .ifab-cookie-custom-checkmark:after {
	border-color : $brand-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ifab-cookie-custom-checkmark:after {
	content  : "";
	position : absolute;
	display  : none;
}

/* Show the checkmark when checked */
.ifab-cookie-option-container input:checked ~ .ifab-cookie-custom-checkmark:after {
	display : block;
}

/* Style the checkmark/indicator */
.ifab-cookie-option-container .ifab-cookie-custom-checkmark:after {
	left         : 5px;
	bottom       : 2px;
	width        : 7px;
	height       : 15px;
	border       : solid $brand-primary;
	border-width : 0 3px 3px 0;
	transform    : rotate(45deg);
}

.ifab-cookie-option-text-container {
	flex        : 70%;
	min-width   : 70%;
	margin-left : 2.5rem;

	label {
		position : relative;
		top      : 4px;

		> i {
			left       : $margin-base-horizontal-small;
			position   : relative;
			transform  : none;
			transition : transform 0.5s ease-in-out;
		}

		&[aria-expanded="true"] {
			> i {
				transform : rotate(180deg);
			}
		}

		&[data-toggle="collapseOff"] {
			> i, > i::before {
				display : none;
			}
		}
	}

	span {
		display : block;
	}
}

.ifab-cookie-option-container label {
	cursor : pointer;
}

#ifab-cookie-submit-container {
	text-align : center;
}

#ifab-cookie-show-banner-button {
	$dim : $margin-base-horizontal-large * 2;
	background          : url('../vendors/plugins/ifab-cookie-banner/images/cookie.svg') center center no-repeat $brand-primary;
	background-size     : ($dim / 1.5);
	color               : transparent;
	cursor              : pointer;
	height              : $dim;
	width               : $dim;
	position            : fixed;
	border-radius       : 0;
	border              : none;
	animation           : fadein 0.5s;
	animation-fill-mode : forwards;
	outline             : none;
	z-index             : 1000;
}

#ifab-cookie-show-banner-button:hover {
	background-color : color('primary');
}
