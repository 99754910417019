/**
 * Module
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Globale Defintionen.
 *
 * ========================================================================== */

.module {}

/* =============================================================================
 *
 * Moduleimport.
 *
 * ========================================================================== */

@import 'imas/_imas';
@import 'districtfinder/_districtfinder';