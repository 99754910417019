/**
 * Video, Audio (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =========================================================================
 *
 * Audio, Video.
 *
 * -------------------------------------------------------------------------
 * Unsichtbar setzen bis der JS-Part zum Zuge kommt. Er stellt die
 * Sichtbarkeit dann wieder her (Vermeidung von unschoenen Browsermeldungen).
 * ====================================================================== */

//audio, video { visibility: hidden; }

video { background-color: transparent; }

/* -------------------------------------------------------------------------
 * Video
 * ---------------------------------------------------------------------- */

.figure.video {
	overflow : hidden;

	/*
	 * Ausrrichtung
	 */

	&.left {
		float  : left;
		margin : 0 $video-width-floated-horizontal-margin $thumbnail-align-margin-vertical 0;
	}

	&.right {
		float  : right;
		margin : 0 0 $thumbnail-align-margin-vertical $video-width-floated-horizontal-margin;
	}

	&.left,
	&.right {
		width : $video-width-floated;

		&.embed-responsive-16by9 { padding-bottom : (56.25% / 2); }

		&.embed-responsive-4by3 { padding-bottom : (75% / 2); }
	}
}