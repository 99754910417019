/**
 * IMAS - Elemente
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/* =============================================================================
 *
 * Indikator
 *
 * ========================================================================== */

.module-imas {
	.indicator {
		background-color : rgba($color-white, 0.6);
		bottom           : 0;
		display          : none;
		height           : auto;
		left             : 0;
		right            : 0;
		top              : 0;
		z-index          : 15;
	}
}

/* =============================================================================
 *
 * Suchformular
 *
 * ========================================================================== */

.form-imas-search {
	$-submit-offset : ceil($input-height / 2);

	background-color : rgba($brand-secondary, 0.9);
	padding          : $padding-base-vertical $padding-base-horizontal-large ($padding-base-vertical + $padding-base-vertical-large);
	position         : relative;

	> * { z-index : 1; }

	> fieldset {
		margin  : 0;
		z-index : 11;

		//&,
		//.row {
		//	&.is-focused { z-index : 90; }
		//}

		&.is-focused { z-index : 15; }
	}

	.row {
		&,
		> * {
			z-index : 1;

			&.is-focused { z-index : 5; }
		}
	}

	label { color : $color-white; }

	.legend {
		color   : $brand-primary;
		display : block;
	}

	.form-control { background-color : $color-white; }

	.form-group {
		/* z-index behebt die Überlappung von Slider und Formularelementen! */

		margin-bottom : $margin-base-vertical;
		margin-top    : $margin-base-vertical;
		z-index       : 5;

		&.has-rangeslider { z-index : 1; }

		.is-focused { z-index : 90; }
	}

	.checkbox-styled label {
		&::before {
			border           : 0 none;
			background-color : $brand-tertiary;
		}

		&::after { color : $brand-primary; }
	}

	.bootstrap-select.form-control { background-color : transparent; }

	.imas-filter {
		&-trigger {
			color       : $brand-primary;
			cursor      : $cursor-pointer;
			display     : block;
			font-size   : cast-unit($font-unit-base, $font-size-large);
			font-weight : $font-weight-bold;
			padding     : $padding-base-vertical-small 0;
			text-align  : center;

			&::after {
				@extend .fa-icon-helper;

				content     : "\f107";
				margin-left : $margin-base-horizontal-small;
			}
			&.is-active::after { content : "\f106"; }
		}

		&-more {
			display: none;
		}
	}

	.range-base-rent { visibility : hidden; }

	.form {
		&-blocker {
			background-color : rgba($brand-secondary, 0.5);
			bottom           : 0;
			display          : none;
			left             : 0;
			position         : absolute;
			right            : 0;
			top              : 0;
			z-index          : 100;
		}

		&-submit {
			bottom     : -#{$-submit-offset};
			left       : 0;
			position   : absolute;
			right      : 0;
			text-align : center;
			z-index    : 10;

			> .wrap {
				display  : inline-block;
				overflow : hidden;
				position : relative;
			}

			.btn {
				//border  : 0 none;
				margin-left  : 1px;
				margin-right : 1px;
				z-index      : 1;

				.btn-text { white-space : nowrap; }
			}

			.indicator {
				background-color : $brand-primary;
				bottom           : 0;
				display          : none;
				height           : auto;
				left             : 0;
				right            : 0;
				top              : 0;
				z-index          : 2;

				&::after { border-color : $color-white; }
			}
		}
	}

	&.is-loading {
		.form {
			&-blocker { display : block; }

			&-submit {
				//> .wrap {
				//	min-width : 1px;
				//	width     : $input-height;
				//}

				.btn {
					opacity    : 0;
					visibility : hidden;
				}

				.indicator {
					display     : block;
					left        : 50%;
					margin-left : -25px;
					width       : 50px;
				}
			}
		}
	}

	//@at-root .view-index .form-imas-search {
	//	.imas-filter-more,
	//	.trigger-imas-filter-more { display : none; }
	//}
}

//@include respond-to(sm) {}
//@include respond-to(xs) {}
@include respond-to(c640) {
	.form-imas-search {
		padding: 20px 20px 30px;

		.col-xs-6 { width : 100%; }
	}
}

@include respond-to(c420) {
	.form-imas-search {
		.form-submit {
			.btn[data-imas-search="reset-filter"] .btn-text {display: none;}
		}
	}
}
//@media print {}