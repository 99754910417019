/**
 * Extra Small Devices, Phones.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

@include respond-to(xs) {

	.system-mq .xs,
	.display-xs-block { display : block; }

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.checkbox-inline,
	.radio-inline {
		&.display-xs-block { margin-left : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */

	/*
	 * Liste Spaltigkeit
	 */

	.list-col > li {
		width : 50%;

		//&:nth-child(4n+1) { clear : none; }
		&:nth-child(2n+1) { clear : both; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ----------------------------------------------------------------------

	.gallery[data-bind="gallery-showcase"] .swiper-slide {}*/

	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */

	//.googlemap:not(responsive-16by9):not(responsive-4by3):not(responsive-3by4):not(responsive-3by2):not(responsive-2by3) { height : 300px; }
	.googlemap:not([class*="responsive-"]) { height : 300px; }

	/* -------------------------------------------------------------------------
	 * @ 'components/_articles'
	 * ---------------------------------------------------------------------- */

	.article-box.property-box {
		> .article-box-wrap { padding-bottom : $padding-base-horizontal-small; }

		.controls {
			bottom     : auto;
			left       : auto;
			padding    : 0;
			position   : relative;
			right      : auto;
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_stacks'
	 * ---------------------------------------------------------------------- */

	/**
	 * Artikelliste
	 */

	.stack-article .stack-item {
		/**
		 * Elemente fuer Spaltigkeit anpassen.
		 */

		/* Rahmen des zweiten Elementes wieder herstellen? (1) */
		@if (
			variable-exists(stack-item-border-top) and
			$stack-item-border-top != ''
		) {
			&:nth-child(2) > a {
				border-top  : $stack-item-border-top;
				padding-top : $stack-item-margin;
			}
		}
	}

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */

	.main-page > .section {
		$-padding : ($padding-base-vertical * 2);

		padding-bottom : $-padding;
		padding-top    : $-padding;
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */

	/*
	 * Service
	 */

	.nav-service > ul > li { margin : 0 $margin-base-horizontal-small; }

	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	.main-footer-wrap {
		$-margin-v : ($margin-base-horizontal-large + $margin-base-horizontal-small);

		> .nav-service,
		> .copy {
			display    : block;
			float      : none;
			left       : auto;
			right      : auto;
			text-align : center;
			margin-top: $-margin-v;
			width      : auto;
		}

		> :first-child { margin-top : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */

	$-showcase-height : 400px;

	.main-showcase {
		> .gallery[data-bind="gallery-showcase"] .swiper {
			&-pagination { bottom : 150px; }

			&-slide {
				&,
				&-wrapper { height : $-showcase-height; }
			}
		}
	}

	.view-index .main-showcase {
		height : $-showcase-height;

		> .component {
			bottom   : 0;
			left     : 0;
			position : absolute;
			right    : 0;
		}
	}

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

}