/**
 * Wells (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.well {
	padding          : $well-padding;
	margin-bottom    : $well-margin;
	background-color : $well-bg-color;

	border           : $well-border;
	border-radius    : $well-border-radius;
	box-shadow       : $well-box-shadow;

	/* TODO: Wells > Blockquote noch offen
	blockquote {
		border-color : #ddd;
		border-color : rgba(0, 0, 0, .15);
	}*/

	> :first-child { margin-top : 0; }

	> :last-child { margin-bottom : 0; }

	&-lg {
		border-radius : $well-large-border-radius;
		margin        : $well-large-margin;
		padding       : $well-large-padding;
	}

	&-sm {
		border-radius : $well-small-border-radius;
		margin        : $well-small-margin;
		padding       : $well-small-padding;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		border-bottom  : 0;
		font-size      : cast-unit($font-unit-base, $font-size-base);
		font-weight    : $font-weight-bold;
		margin         : $margin-base-vertical-small 0;
		padding-bottom : 0;
	}

	h1,
	h2 {
		font-size   : cast-unit($font-unit-base, $h2-font-size);
		font-weight : $font-weight-normal;
	}

	h3 {
		font-size   : cast-unit($font-unit-base, $h3-font-size);
		font-weight : $font-weight-normal;
	}
}

.well-scrollable{
	height     : $well-scrollable-height;
	overflow-x : hidden;
	overflow-y : auto;

	&-lg { height : $well-scrollable-height-large; }

	&-sm { height : $well-scrollable-height-small; }
}