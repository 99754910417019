/**
 * Block-Level-Element zentrieren.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

%center-block {
	display      : block;
	margin-left  : auto;
	margin-right : auto;
}