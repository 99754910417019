/**
 * IMAS - Detailansicht
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.imas-detail {
	> .article {
		$-top-offset : ($margin-base-vertical-large * 2);

		margin-bottom : 0 !important;
		margin-top    : -#{$-top-offset};
		max-width     : none;
		padding-left  : 0;
		padding-right : 0;
	}

	.module-header {
		background-color : $color-gray-lighter;
		color            : $color-gray-dark;
		max-width        : none;
		padding-left     : 0;
		padding-right    : 0;
		padding-top      : ($margin-base-vertical-large * 2);

		.title {
			color : $color-gray-dark;

			&::before { background-color : $color-gray-dark; }
		}

		.datetime { display : none; }
	}

	.module-body > * {
		@extend .page-max-width;
	}

	.article-body-section {
		> .row > * {
			> :first-child { margin-top : 0; }

			> :last-child { margin-bottom : 0; }
		}
	}

	h2 { color : $color-gray-dark; }
}

/* =============================================================================
 *
 * Ansichten (Galerie, Icons).
 *
 * ========================================================================== */

.imas-detail .property-views {
	background-color : $color-gray-lighter;
	margin-bottom    : ($margin-base-vertical + $margin-base-vertical-large) !important;
	margin-top       : 0 !important;
	max-width        : none;
	padding-left     : 0;
	padding-right    : 0;

	.gallery[data-bind*="gallery"] {
		margin  : 0;

		.swiper-slide {
			// TODO(Dev): Höhe kommt aktuell über die Thumbnails (format-16by9)!
			//&,
			//&-wrapper { height : 600px; }

			.thumbnail {
				height  : 100%;
				z-index : 1;

				> .image {
					background-position : center;
					height              : 100%;
				}
			}
		}

		.swiper-control {
			$-size-half : ceil($gallery-ctrl-size / 2);
			$-h-offset  : $gallery-ctrl-size + $padding-base-horizontal-xsmall;

			bottom : -#{$-size-half};
			margin : auto;
			top    : auto;

			&.prev {
				left        : 50%;
				margin-left : -#{$-h-offset};
				right       : auto;
			}

			&.next {
				left         : auto;
				margin-right : -#{$-h-offset};
				right        : 50%;
			}
		}

		.swiper-count {
			left  : auto;
			right : $thumbnail-zoom-size;
		}
	}
}

/* =============================================================================
 *
 * Optionen.
 *
 * ========================================================================== */

.imas-detail .property-options {
	margin-top  : ($margin-base-vertical + $margin-base-vertical-large) !important;

	> div {
		border-bottom  : 1px solid $color-gray-light;
		padding-bottom : $padding-base-vertical;

		> * {
			float       : right;
			margin-left : $margin-base-horizontal-large;
		}

		> address {
			color      : $color-gray-dark;
			float      : left;
			font-size  : cast-unit($font-unit-base, $font-size-large);
			font-style : $font-style-italic;
			margin     : 0;
			width      : 50%;
		}

		> a {
			font-weight: $font-weight-bold;

			&::before {
				font-size : cast-unit($font-unit-base, $font-size-large);
				top       : 1px;
			}
		}

	}
}

/* =============================================================================
 *
 * Daten.
 *
 * ========================================================================== */

.imas-detail .property-data {
	table > tbody > tr {
		> th,
		> td {
			color          : $brand-secondary;
			font-weight    : $font-weight-light;
		}

		> :first-child {
			padding-left : 0;
			width        : 33%;
		}

		> :last-child { padding-right : 0; }

		&:last-child {
			> th,
			> td { border-bottom : 0 none; }
		}
	}

	address,
	.accordion { margin-top: $margin-base-vertical-large; }

	.googlemap + address { margin-top : $margin-base-vertical-xsmall; }

	.jumbotron {
		background-color : $color-gray-lighter;
		height           : 100%;
		padding          : ($padding-base-vertical + $padding-base-vertical-small);

		> * {
			display      : block;
			margin       : $margin-base-vertical-small 0;
			padding-left : $padding-base-horizontal-large;
			position     : relative;
		}

		> h2 {
			color         : $brand-secondary;
			margin-bottom : $margin-base-vertical-large;
			padding-left  : 0;

			&::before { display : none; }
		}

		> p,
		> ul,
		> span {
			color        : $color-gray-dark;
			font-size    : inherit;
			font-weight  : $font-weight-light;
		}

		> ul { padding-left : 0; }

		> p,
		> ul > li,
		> span {

			&::before {
				@extend .fa-icon-helper;

				color     : $brand-secondary;
				content   : "\f00c";
				font-size : 1.125em;
				left      : 0;
				position  : absolute;
				top       : .125em;
			}
		}

		> :first-child { margin-top : 0; }

		> :last-child { margin-bottom : 0; }
	}
}

/* =============================================================================
 *
 * Kontakt.
 *
 * ========================================================================== */

.imas-detail .property-contact {
	.vcard {
		margin-bottom : $margin-base-vertical-large;

		&:last-child { margin-bottom : 0; }
	}
}

/* =============================================================================
 *
 * Mediaqueries.
 *
 * ========================================================================== */

//@include respond-to(sm) {}
//@include respond-to(xs) {}
//@include respond-to(c640) {}
//@include respond-to(c420) {}

/* =============================================================================
 *
 * Printquery.
 *
 * ========================================================================== */

@media print {
	.imas-detail {
		.module-body > * {
			max-width     : none;
			padding-left  : 0;
			padding-right : 0;
			width         : 100%;
		}

		.property- {
			&contact,
			&data {
				.row > div { height : auto !important; }
			}
		}

		.property-views { display : none; }

		.property-options {
			margin-top : $margin-base-vertical !important;

			> div {
				> address {
					display    : block;
					float      : none;
					width      : 100%;
					text-align : center;
				}

				> a { display : none !important; }
			}
		}

		.property-data {
			 .row > div {
				 &:first-child { margin-bottom : $margin-base-vertical-large; }
				 &:last-child { margin-bottom : 0; }
			 }

			.jumbotron {
				> p,
				> span,
				> ul > li { page-break-inside : avoid; }
			}
		}

		.property-contact {
			.row > div {
				left  : 0;
				width : 100%;
			}
		}
	}
}