/**
 * Block-Level-Element zentrieren.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

%clear-both { clear : both; }

%clear-left { clear : left; }

%clear-right { clear : right; }