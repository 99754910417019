/**
 * Stadtteilfinder: Fragestellungen
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

*[data-districtfinder="questions"] > .article-body {
	@extend .page-max-width;

	> form {
		margin-top  : $df-spacing-large;
		padding-top : $df-spacing-large;
		position    : relative;

		> * { z-index : 1; }

		> .indicator {
			background-color : rgba($color-white, 0.8);
			bottom           : 0;
			height           : auto;
			left             : 0;
			right            : 0;
			top              : $df-spacing-large;
			z-index          : 10;
		}

		&:first-child { margin-top : 0; }
	}

	.df-answers {
		> .df-answer {
			float : left;
			width : 25%;

			> .radio label { display : block; }
		}
	}

	.df-answer-important {
		text-align  : right;
		white-space : nowrap;

		> * { display : inline-block; }

		> .answer-important-disabled {
			opacity : .3;

			* { cursor : $cursor-default; }
		}
	}

	/* Fortschrittsanzeige der Fragen. */
	.df-progress {
		//align-content   : stretch;
		//align-items     : stretch;
		background-color : $df-q-progress-bg-color;
		border-bottom    : $df-q-progress-border-width $df-q-progress-border-style $df-q-progress-border-color;
		display          : flex;
		flex-flow        : row nowrap;
		//justify-content : space-between;
		margin-top       : $df-spacing-medium;
		overflow         : hidden;
		position         : relative;

		> span {
			background-color : $df-q-progress-item-bg-color;
			color            : $df-q-progress-item-color;
			cursor           : $cursor-disabled;
			display          : flex;
			flex             : 1 0 5%;
			height           : $df-q-progress-item-height;
			//justify-content  : center;
			overflow         : hidden;
			position         : relative;
			transition       : background-color 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
			                   color 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
			white-space      : nowrap;

			&::before {
				bottom     : 0;
				content    : '';
				display    : block;
				left       : 0;
				overflow   : hidden;
				position   : absolute;
				top        : 0;
				transition : all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
				visibility : hidden;
				width      : 0;
			}

			&.is-current {
				background-color : $df-q-progress-item-current-bg-color;
				color            : $df-q-progress-item-current-color;
			}

			&.is-answered {
				opacity : 1;

				&::before {
					background-color : $df-q-progress-item-answered-bg-color;
					color            : $df-q-progress-item-answered-color;
					opacity          : 1;
					visibility       : visible;
					width            : 100%;
				}
			}
		}

		&.is-clickable > * {
			&.is-answered {
				cursor : $cursor-pointer;

				&:hover,
				&:focus,
				&:active {background-color : lighten($df-q-progress-item-answered-bg-color, 20%);}

				/* nachfolgendes unbeantwortetes Element muss auswaehlbar sein. */
				+ span:not(.is-answered):not(.is-current) {
					&:hover,
					&:focus,
					&:active {
						background-color : $df-q-progress-item-current-bg-color;
						color            : $df-q-progress-item-current-color;
						cursor           : $cursor-pointer;
					}
				}
			}
		}
	}

	/* Buttons "vorige, naechste Frage". */
	.df-ctrl {
		margin-top : $df-spacing-medium;
		float      : left;

		&-prev-question {
			color        : $color-gray;
			padding-left : 0;

			> i {
				color        : $brand-primary;
				font-size    : 1.5em;
				margin-right : $margin-base-horizontal;
				position     : relative;
				top          : .125em;
			}

			&:hover,
			&:focus,
			&:active {
				color           : $brand-primary;
				text-decoration : none;
			}

			&[disabled],
			&.df-ctrl-disabled {
				color        : $color-gray;
				opacity      : 1;

				> i { display : none; }
			}
		}

		&-next-question { float : right; }
	}
}

@media print {

	*[data-districtfinder="questions"] > .article-body {
		max-width     : none;
		padding-left  : 0;
		padding-right : 0;
		width         : 100%;
	}

}