/**
 * Stapel
 *
 * Komponentendefinitionen
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package Workspace
 */

/*.stack {
	margin-bottom : $margin-base-vertical;
	margin-top    : $margin-base-vertical;

	.stack-item {
		margin   : $margin-base-vertical 0;
		position : relative;

		&:first-child { margin-top : 0; }

		&:last-child { margin-bottom : 0; }
	}
}*/

/* =============================================================================
 *
 * Artikel
 *
 * ==========================================================================

.stack-article .stack-item {
	margin-bottom : $stack-article-item-margin;
	margin-top    : $stack-article-item-margin;
	position      : relative;

	// (1)
	@if (
		variable-exists(stack-article-delimiter-border) and
		$stack-article-delimiter-border != ''
	) {
		border-top  : $stack-article-delimiter-border;
		padding-top : $stack-article-item-margin;
	}

	&:first-child {
		border-top  : 0 none;
		margin-top  : 0;
		padding-top : 0;
	}

	&:last-child { margin-bottom : 0; }

	> div {
		> * {
			clear      : none;
			margin-top : $margin-base-vertical-large;
		}

		> .title {
			color      : $stack-article-title-color;
			margin-top : 0;

			// (1)
			@if (variable-exists(stack-article-title-font-weight)) {
				font-weight    : $stack-article-title-font-weight;
			}

			// (1)
			@if (variable-exists(stack-article-title-text-transform)) {
				text-transform : $stack-article-title-text-transform;
			}

			// (1)(2)
			@if (
				variable-exists(stack-article-title-font-size) and
				type-of($stack-article-title-font-size) == 'number' and
				unitless($stack-article-title-font-size) == false and
				unit($stack-article-title-font-size) == 'px'
			) {
				font-size : cast-unit($font-unit-base, $stack-article-title-font-size);
			}
		}

		> .description {
			background-color : transparent;
			padding          : 0;
		}

		> .datetime { @extend %hidden; }

		> .btn-readmore {
			border      : 0 none;
			height      : auto;
			line-height : normal;
			margin-top  : 0;
			padding     : 0;
			position    : relative;

			&::before {
				@extend .fa-icon-helper;

				content      : "\f061";
				font-size    : 90%;
				margin-right : ($margin-base-horizontal-small - 4);
				top          : -1px;
				position     : relative;
			}

		}
	}

	&.has-img {
		> div { margin-left : ($stack-article-image-width + $stack-article-image-offset); }

		> img {
			float       : left;
			height      : auto;
			margin-left : 0;
			width       : $stack-article-image-width;
		}
	}
} */

/* =============================================================================
 *
 * Artikelboxen
 *
 * ========================================================================== */

.stack-article-box {
	margin-top: -2.5em;
	//padding-bottom : ($padding-base-vertical-large + $padding-base-vertical);

	> .article-box {
		&,
		&:first-child,
		&:last-child {
			margin-bottom : 3.5em;
			margin-top    : 3.5em;
		}
	}

	+ .nav-pager { margin-top : $margin-base-vertical-large; }
}