/**
 * Utilities
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

/*
 * Floats
 */

.clearfix {
	@include clearfix();
}

.clearfloat {
	@extend %clear-both;
}

.float-left { float : left; }

.float-right { float : right; }

/*
 * Zentrierung von Bloecken.
 */

.center-block {
	@extend %center-block;
}

/*
 * Sichtbarkeit von Elementen.
 */

.show { display: block !important; }

.invisible { visibility: hidden !important; }

/* Ausblenden fuer Screenreader und Browser (Credit: HTML5 Boilerplate). */
.hidden {
	display    : none !important;
	visibility : hidden !important;
}

/*
 * Overflow-Eigenschaften.
 */

.prevent-overflow {
	@include overflow;
}

.prevent-overflow-x {
	@include overflow(x, hidden);
}

.prevent-overflow-y {
	@include overflow(y, hidden);
}

/*
 * Zeilenumbruch verhindern.
 */

.prevent-text-break { white-space : nowrap; }

/*
 * Element klickbar (Cursor setzen).
 */

.is-clickable { cursor : $cursor-pointer; }

/* =============================================================================
 *
 * Aussen-, Innenabstaende setzen/entfernen.
 *
 * ========================================================================== */

.no-margin { margin : 0; }

.no-bottom-margin { margin-bottom : 0; }

.no-left-margin { margin-left : 0; }

.no-right-margin { margin-right : 0; }

.no-top-margin { margin-top : 0; }