/**
 * Seitenkopf
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.main-header {
	$-logo-target-height : 34px;

	> .main-header-wrap {
		align-content   : stretch;
		display         : flex;
		flex-flow       : row wrap;
		height          : $main-header-height;
		justify-content : space-between;
		position        : relative;
		align-items     : stretch;

		> .brand,
		> .nav {
			flex            : 0 1 auto;
			display         : flex;
			position        : relative;
			justify-content : center;
			align-items     : center;
		}
	}

	/**
	 * Logo
	 */

	.brand > span {
		// calc-aspect-ratio-to-target(img-width-org, img-height-org, img-width-new, img-height-new);
		$-logo-width      : calc-aspect-ratio-to-target($logo-origin-width, $logo-origin-height, 0, $-logo-target-height);
		$-logo-top-offset : ceil($main-header-height / 2) - ceil($-logo-target-height / 2);

		background-size : contain;
		background      : transparent url(../images/logo.svg) no-repeat 0 0;
		display         : block;
		height          : 0;
		overflow        : hidden;
		padding-top     : $-logo-target-height; // Padding-Hack IE
		transition      : all 0s;
		width           : #{$-logo-width}px;

		> img { visibility : hidden; }
	}

	.nav > ul { height : $-logo-target-height; }

	/* Trigger */
	.trigger {
		$-trigger-height     : $-logo-target-height;
		$-trigger-top-offset : ceil($main-header-height / 2) - ceil($-trigger-height / 2);

		&,
		> i {
			position    : absolute;
			height      : $-trigger-height;
			line-height : $-trigger-height;
			width       : $-trigger-height;
		}

		color           : $brand-primary;
		display         : none;
		font-size       : cast-unit($font-unit-base, 29px);
		font-weight     : $font-weight-bold;
		right           : $padding-base-horizontal;
		padding-right   : $-trigger-height;
		text-decoration : none;
		text-transform  : uppercase;
		top             : $-trigger-top-offset;

		> span { display : none; }

		> i {
			top       : 0;
			font-size : 1.4375em;
			right     : 0;
		}
	}
}