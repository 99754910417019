/**
 * Accordions (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 * @package namRED
 */

.accordion {
	/* -------------------------------------------------------------------------
	 * Zuweisungen der Eigenschaften/Defintionen.
	 * ---------------------------------------------------------------------- */

	@extend %child-reset-margin-last-top;

	display : block;
	z-index : 1;

	&-toggle {
		&,
		&::before { @extend %transition-colors; }
	}

	&-toggle {
		@extend %transition-colors;

		background-color                        : $accordion-toggle-bg-color;
		border                                  : $accordion-toggle-border-width $accordion-toggle-border-style $accordion-toggle-border-color;
		color                                   : $accordion-toggle-color;
		cursor                                  : $cursor-pointer;
		display                                 : block;
		margin                                  : $accordion-toggle-margin;
		outline                                 : 0 !important;
		padding                                 : $accordion-toggle-padding;
		padding-#{$accordion-toggle-icon-align} : ($accordion-toggle-icon-height + $comp-padding-horizontal-small);
		position                                : relative;
		text-decoration                         : none;
		text-transform                          : uppercase;
		white-space                             : nowrap;

		// (1)(2)
		@if (
			variable-exists(accordion-toggle-font-size) and
			type-of($accordion-toggle-font-size) == 'number' and
			unitless($accordion-toggle-font-size) == false and
			unit($accordion-toggle-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $accordion-toggle-font-size);
		}

		// (1)
		@if (
			variable-exists(accordion-toggle-font-weight) and
			$accordion-toggle-font-weight != ''
		) {
			font-weight : $accordion-toggle-font-weight;
		}

		&::before {
			$-top-offset : ceil($accordion-toggle-icon-height / 2);

			@extend .fa-icon-helper;
			@extend %transition-colors;

			#{$accordion-toggle-icon-align} : $comp-padding-horizontal-small;
			background-color : $accordion-toggle-icon-bg-color;
			border-radius    : $accordion-toggle-icon-border-radius;
			bottom           : 0;
			color            : $accordion-toggle-icon-color;
			content          : $accordion-toggle-icon-content;
			font-size        : $accordion-toggle-icon-font-size;
			height           : $accordion-toggle-icon-height;
			line-height      : ($accordion-toggle-icon-height - 4);
			overflow         : hidden;
			padding-left     : 2px;
			position         : absolute;
			text-align       : center;
			top              : 50%;
			margin-top       : -#{$-top-offset};
			width            : $accordion-toggle-icon-height;
		}

		&:hover,
		&:focus {
			background-color: $accordion-toggle-bg-color-hover;
			border-color    : $accordion-toggle-border-color-hover;
			color           : $accordion-toggle-color-hover;

			&::before {
				background-color : $accordion-toggle-icon-bg-color-hover;
				color            : $accordion-toggle-icon-color-hover;
				content          : $accordion-toggle-icon-content-hover;
			}
		}

		&:active,
		&.active {
			background-color : $accordion-toggle-bg-color-active;
			border-color     : $accordion-toggle-border-color-active;
			color            : $accordion-toggle-color-active;

			&::before {
				background-color : $accordion-toggle-icon-bg-color-active;
				color            : $accordion-toggle-icon-color-active;
				content          : $accordion-toggle-icon-content-active;
				line-height      : ($accordion-toggle-icon-height - 2px);
				padding-left     : 0;
			}
		}

		&:first-child { margin-top: 0; }
	}

	&-panel {
		display : none;

		&-body {
			border-bottom : $accordion-panel-border-width $accordion-panel-border-style $accordion-panel-border-color;
			padding       : $accordion-panel-padding;

			@extend %child-reset-margin-last-top;
		}
	}
}